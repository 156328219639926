import React from "react";
import Header from "../components/header";
import MaskGroup from "../img/aboutUs/Maskgroup.png";

const AboutUs: React.FC = () => {
  return (
    <div
      className="min-h-screen relative"
      style={{ backgroundColor: "#F5F3FF", paddingTop: "20px" }}
    >
      {/* Header */}
      <Header showBackground={false} />

      {/* Background Circle */}
      <div
        className="absolute w-full flex justify-center"
        style={{
          top: "-150px",
          left: "0",
          height: "400px",
          zIndex: "0",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            width: "582px",
            height: "582px",
            backgroundColor: "#492CBB",
            borderRadius: "50%",
            position: "absolute",
            top: "-200px",
          }}
        ></div>
      </div>

      {/* Main Content */}
      <div className="relative z-10 flex flex-col items-center text-center px-6">
        {/* Circle Content */}
        <div
          className="relative"
          style={{
            marginTop: "10px",
            textAlign: "center",
          }}
        >
          <h1
            className="text-[28px] font-bold"
            style={{
              color: "#FCB514", 
              fontFamily: "Graphik, sans-serif",
              lineHeight: "36.4px",
            }}
          >
            About
          </h1>
          <h1
            className="text-[35px] font-bold"
            style={{
              color: "#FFFFFF",
              fontFamily: "Graphik, sans-serif",
              lineHeight: "45.4px",
            }}
          >
            TeamFlo
          </h1>
        </div>

        {/* Subtitle */}
        <h2
          className="mt-8 text-[28px] font-bold text-[#000000]" 
          style={{
            lineHeight: "36.4px",
            fontFamily: "Graphik, sans-serif",
            marginTop: "90px",
          }}
        >
          We believe in empowering <br /> teams to reach their fullest potential.
        </h2>

        <img
          src={MaskGroup}
          alt="Mask Group Normal"
          style={{ width: "300px", height: "auto", marginTop: "30px" }}
        />

        {/* Text Content */}
        <div
          className="mt-6 text-left"
          style={{
            maxWidth: "360px",
          }}
        >
          <p
            className="text-[#53565A] text-[18px] leading-[27px]" 
            style={{
              fontFamily: "Graphik, sans-serif",
              marginTop: "-90px",
            }}
          >
            At TeamFlo, we’re all about empowering teams and recognizing everyone’s efforts.
          </p>
          <p
            className="mt-4 text-[#53565A] text-[18px]"
            style={{
              fontFamily: "Graphik, sans-serif",
              lineHeight: "27px",
            }}
          >
            <span style={{ fontWeight: "normal" }}>Our mission is simple:</span>{" "}
            <span style={{ color: "#53565A", fontWeight: "bold" }}>
              create a space where every team member feels their contributions
              matter.
            </span>
          </p>
          <p
            className="mt-4 text-[#53565A] text-[18px]" 
            style={{
              fontFamily: "Graphik, sans-serif",
              lineHeight: "27px",
            }}
          >
            We’re committed to helping teams track their progress, celebrate wins, and keep growing together.
          </p>
          <p
            className="mt-4 text-[#53565A] text-[18px]" 
            style={{
              fontFamily: "Graphik, sans-serif",
              lineHeight: "27px",
            }}
          >
            By focusing on engagement, sentiment, and care, we’re building a
            culture where people feel valued and inspired to reach new heights
            together.
          </p>
        </div>

        {/* Images */}
        <div className="mt-8 flex flex-col items-center">
          <img
            src={MaskGroup}
            alt="Mask Group Inverted"
            style={{
              width: "300px",
              height: "auto",
              transform: "rotate(180deg)",
              marginTop: "-80px",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
