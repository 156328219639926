import React from "react";
import { useNavigate } from "react-router-dom";
import onboardingDetailsUpPage1 from "../../img/quickGuide/onboardingDetailsUpPage1.svg";
import onboardingDetailsDownPage1 from "../../img/quickGuide/onboardingDetailsDownPage1.svg";
import badge1 from "../../img/quickGuide/badge1.svg";
import badge2 from "../../img/quickGuide/badge2.svg";
import badge3 from "../../img/quickGuide/badge3.svg";
import badge4 from "../../img/quickGuide/badge4.svg";

interface OnboardingProps {
  nextStep: () => void;
  prevStep: () => void;
  currentStep: number;
  showNavigationBar?: boolean;
}

const Onboarding06: React.FC<OnboardingProps> = ({ nextStep, prevStep, currentStep }) => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        height: "100vh",
        backgroundColor: "#F5F3FF",
        padding: "32px",
        position: "relative",
        textAlign: "center",
        overflow: "hidden",
      }}
    >
      <img
        src={onboardingDetailsUpPage1}
        alt="Background Wave Up"
        style={{
          position: "absolute",
          top: "0px",
          left: "0px",
          width: "200%",
          height: "auto",
          zIndex: 0,
          opacity: 0.75,
        }}
      />

      <img
        src={onboardingDetailsDownPage1}
        alt="Background Wave Down"
        style={{
          position: "absolute",
          bottom: "0px",
          right: "0px",
          width: "200%",
          height: "auto",
          zIndex: 0,
          opacity: 0.75,
          transform: "rotate(0)",
        }}
      />

      <button
        onClick={() => navigate("/dashboard/menu")}
        style={{
          position: "absolute",
          top: "16px",
          right: "16px",
          fontSize: "16px",
          fontWeight: "bold",
          color: "#6A4DF8",
          background: "none",
          border: "none",
          cursor: "pointer",
          zIndex: 1,
        }}
      >
        skip
      </button>

      <h1
        style={{
          fontSize: "30px", 
          fontWeight: 700,
          color: "#220D4E",
          fontFamily: "Graphik, sans-serif",
          lineHeight: "33px",
          marginBottom: "16px",
          zIndex: 1,
        }}
      >
        Earn Badges <br /> as You Grow
      </h1>

      <div
        style={{
          position: "relative",
          width: "300px",
          height: "300px",
        }}
      >
        <img
          src={badge1}
          alt="Badge 1"
          style={{
            position: "absolute",
            top: "60%",
            left: "50%",
            transform: "translate(-50%, -150%)",
            width: "157px",
            height: "139px",
          }}
        />
        <img
          src={badge2}
          alt="Badge 2"
          style={{
            position: "absolute",
            top: "29%",
            left: "-10%",
            width: "157px",
            height: "139px",
          }}
        />
        <img
          src={badge3}
          alt="Badge 3"
          style={{
            position: "absolute",
            bottom: "24%",
            left: "55%",
            width: "157px",
            height: "139px",
          }}
        />
        <img
          src={badge4}
          alt="Badge 4"
          style={{
            position: "absolute",
            bottom: "-15%",
            right: "25%",
            width: "157px",
            height: "139px",
          }}
        />
      </div>

      <p
        style={{
          fontSize: "20px",
          fontWeight: 400,
          color: "#220D4E",
          lineHeight: "24px",
          marginBottom: "32px",
          maxWidth: "305px",
          textAlign: "center",
          zIndex: 1,
        }}
      >
        Track your progress and earn badges for your contributions, consistency,
        and engagement.
      </p>

      {/* nav bar */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          maxWidth: "300px",
          zIndex: 1,
        }}
      >
        <button
          onClick={prevStep}
          style={{
            border: "none",
            backgroundColor: "transparent",
            fontSize: "24px",
            color: "#6A4DF8",
            cursor: "pointer",
          }}
        >
          &lt;
        </button>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "8px",
          }}
        >
          {Array(7)
            .fill(null)
            .map((_, index) => (
              <div
                key={index}
                style={{
                  width: index === currentStep ? "24px" : "8px",
                  height: "8px",
                  borderRadius: index === currentStep ? "4px" : "50%",
                  backgroundColor: index === currentStep ? "#6A4DF8" : "#D0C1FF",
                  transition: "all 0.3s ease",
                }}
              ></div>
            ))}
        </div>
        <button
          onClick={nextStep}
          style={{
            border: "none",
            backgroundColor: "transparent",
            fontSize: "24px",
            color: "#6A4DF8",
            cursor: "pointer",
          }}
        >
          &gt;
        </button>
      </div>
    </div>
  );
};

export default Onboarding06;
