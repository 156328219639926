import axios from "axios";
import { API_URL } from "../config";

const getCookie = (name:string) => {
	let cookieValue = null;
	if (document.cookie && document.cookie !== "") {
		const cookies = document.cookie.split(";");
		for (let i = 0; i < cookies.length; i++) {
			const cookie = cookies[i].trim();
			// Does this cookie string begin with the name we want?
			if (cookie.substring(0, name.length + 1) === name + "=") {
				cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
				break;
			}
		}
		return cookieValue;
	}
};

const defaultHeaders = {
	"Content-Type": "application/json",
	"X-CSRFToken": getCookie("csrftoken"),
};

const authenticatedheaders = (token:string, isFormData = false) => {
	if (isFormData) {
		return {
			Authorization: `Token ${token}`,
			"X-CSRFToken": getCookie("csrftoken"),
		};
	}
	return {
		"Content-Type": "application/json",
		Authorization: `Token ${token}`,
		"X-CSRFToken": getCookie("csrftoken"),
	};
};

export class TeamfloAPI {
    static async getUser(token:string) {
		try {
			const response = await axios.get(`${API_URL}client/user`, {
				headers: authenticatedheaders(token),
			});
			return response.data;			
		} catch (error) {
			console.log("ERROR in getUser", error);
			return false;
		}

    }

	static async getProjectDetails(token:string, project_id:string) {
		try {
			const response = await axios.get(`${API_URL}client/user/project/${project_id}`, {
				headers: authenticatedheaders(token),
			});
			return response.data;			
		} catch (error) {
			console.log("ERROR in getProjects", error);
			return false;
		}

	}

	static async markBadgeAsSeen(token:string, badges:number[]) {
		try {
			const data = {
				badges,
			}
			const response = await axios.post(`${API_URL}client/user/badges/mark-as-seen`,data,{
				headers: authenticatedheaders(token),
			});
			return response.data;
		}
		catch (error) {
			console.log("ERROR in markBadgeAsSeen", error);
			return false;
		}
	}

}