import React from "react"
import { useState, useEffect, useContext, useRef} from "react";
import { useSearchParams } from "react-router-dom";

import { createPortal } from "react-dom"

import { TeamfloAPI } from "../manager/apimanager";

import { DashboardContext } from "../context/DashboardContext";


import badge_icons from "../constants/badges_img";
import { BadgesProps, Badge } from "../types";


function GetBadgeImage(props:any):any{
    var s = props.badge.streak-1;
    if(s>4){
        s = 4;
    }
    //default value
    var src = badge_icons["engagement_individual"][0]
    if(props.badge.badge_type === 'all_rounder'){
        if(props.badge.badge_level==="gold"){
            src = badge_icons[props.badge.badge_type][1]
        }
        else{
            src = badge_icons[props.badge.badge_type][0]
        }
    }
    else{
        src = badge_icons[props.badge.badge_type][s];
    }
    return(<img src={src} alt="" />)
}

const NewBadgesPopup: React.FC<BadgesProps> = ({badges}) => {

    const {fromDate, toDate} = useContext(DashboardContext);

    let [searchParams, setSearchParams] = useSearchParams();

    const [show, setShow] = useState(true); //used for animation
    const [show_, setShow_] = useState(true);//used to actually show the popup
    const [lastBadges, setLastBadges] = useState<Badge[]>([]);

    const slideContainerRef = useRef<HTMLDivElement>(null);


    const [isDragging, setIsDragging] = useState<boolean>(false);
    const [startX, setStartX] = useState<number>(0);
    const [currentX, setCurrentX] = useState<number>(0);
    const [scrollLeft, setScrollLeft] = useState<number>(0);

    const [currentSlide, setCurrentSlide] = useState<number>(0);

    
    useEffect(() => {
        //filter badges based on the dates via the awarded_date property
        if(fromDate !== "" && toDate !== ""){
            const filteredBadges_:any[] = badges.filter((badge)=>{
                const awarded_date = new Date(badge.awarded_date);
                return awarded_date >= new Date(fromDate) && awarded_date <= new Date(toDate);});

                //filter badges that have "have_been_seen" set to false
                const unseenBadges = filteredBadges_.filter((badge) => !badge.has_been_seen);
                
                setLastBadges(unseenBadges);
        }else{
            setLastBadges(badges);
        }
    }, [fromDate, toDate]);

    const dismiss = async () =>{

        if(searchParams.has("token")){
            const token = (searchParams.get("token") as string);
            const ids = lastBadges.map((badge) => badge.id);
            TeamfloAPI.markBadgeAsSeen(token , ids);
        }

        //set this for the animation to run
        setShow(false);
        //set this for the popup to actually disappear
        setTimeout(()=>{
            setShow_(false);
        }, 500);
    }

    if(show_ === false || lastBadges.length === 0){
        return null;
    }

    const handleMouseDown = (e: React.MouseEvent) => {
        if (slideContainerRef.current) {
            setIsDragging(true);
            setStartX(e.pageX - slideContainerRef.current.offsetLeft);
            setScrollLeft(slideContainerRef.current.scrollLeft);
        }
    };
    
    const handleMouseMove = (e: React.MouseEvent) => {
        if (!isDragging || !slideContainerRef.current) return;
    
        e.preventDefault(); // Prevent default browser behavior

        const x = e.pageX - slideContainerRef.current.offsetLeft;
        const walk = (x - startX);
        // Update the scrollLeft with the calculated value
        slideContainerRef.current.scrollLeft = scrollLeft - walk;
        setCurrentX(x);
    };

    const handleTouchStart = (e: React.TouchEvent) => {
        if (slideContainerRef.current) {
            setIsDragging(true);
            setStartX(e.touches[0].pageX - slideContainerRef.current.offsetLeft);
            setScrollLeft(slideContainerRef.current.scrollLeft);
        }
    }

    const handleTouchMove = (e: React.TouchEvent) => {
        if (!isDragging || !slideContainerRef.current) return;


        const x = e.touches[0].pageX - slideContainerRef.current.offsetLeft;
        const walk = (x - startX);
        // Update the scrollLeft with the calculated value
        slideContainerRef.current.scrollLeft = scrollLeft - walk;
        setCurrentX(x);
    };

    const handleMouseUpOrLeave = () => {
        setIsDragging(false);
        let newSlide = currentSlide;

        var diff = Math.abs(currentX - startX);
        if(diff > 50){
            if(currentX > startX){
                //swipe right
                newSlide = currentSlide-1;
            }
            else{
                //swipe left
                newSlide = currentSlide+1;
            }
        }

        if(newSlide < 0){
            newSlide = 0;
        }
        else if(newSlide >= lastBadges.length){
            newSlide = lastBadges.length-1;
        }
        setCurrentSlide(newSlide);

        console.log("newSlide", newSlide);

        // Scroll to the new slide's position
        if (slideContainerRef.current) {
            const slideWidth = slideContainerRef.current.offsetWidth;
            const scrollPosition = newSlide * slideWidth;
            slideContainerRef.current.scrollTo({
                left: scrollPosition,
                behavior: 'smooth', // Ensures smooth scrolling
            });
        }
    };

    const setSlide = (index:number) => {
        if (slideContainerRef.current) {
            const slideWidth = slideContainerRef.current.offsetWidth;
            const scrollPosition = index * slideWidth;
            slideContainerRef.current.scrollTo({
                left: scrollPosition,
                behavior: 'smooth', // Ensures smooth scrolling
            });
            setCurrentSlide(index);
        }
    }

    //console.log("lastBadges", lastBadges);

    return createPortal(
        <div className={"popup-container "+(show?"show":"hide")}>
            <div className={"bottom-card "+(show?"show":"hide")}>

                <div className="slide-container">
                    <div className="slides"
                        ref={slideContainerRef}
                        
                        onMouseDown={handleMouseDown}
                        onMouseMove={handleMouseMove}
                        onMouseUp={handleMouseUpOrLeave}

                        onTouchStart={handleTouchStart}
                        onTouchMove={handleTouchMove}
                        onTouchEnd={handleMouseUpOrLeave}
                        >
                        {lastBadges.map((badge, index) =>{
                            return(
                            <div className="slide">
                                <h4 className="font-medium text-center text-3xl mt-8 max-w-[324px] mx-auto">you are leading the charge</h4>
                                <GetBadgeImage badge={badge} />
                                <p className="text-center text-xl max-w-[16em] mx-auto mb-8">{badge.description}</p>
                            </div>)                   
                            })
                        }
                    </div>
                </div>

                <div className="flex flex-row justify-center items-center">
                    {lastBadges.map((badge, index) =>{
                        return(
                            <div className={"slide-indicator "+(index === currentSlide?"active":"")} onClick={()=>{setSlide(index)}}></div>
                        )
                    })}
                </div>

                <button className="primary-button" onClick={dismiss}>Okay</button>

            </div>
        </div>,
        document.body
    )
}

export default NewBadgesPopup;