import React, { useEffect, useContext } from 'react';
import { DashboardContext } from '../context/DashboardContext';

interface LineDataSet {
    data: number[];
    label: string;
    borderColor: string;
    borderDash?: number[];
}

interface LineChartData {
    labels: string[];
    datasets: LineDataSet[];
}

interface LineProps {
    data: LineChartData;
    width?: number;
    height?: number;
    chartName: string;
    options?: any;
}

const verticalLabels: string[] = ["0%", "20%", "40%", "60%", "80%", "100%"];

const Line = ({ data, width = 500, height = 300, chartName }: LineProps) => {

    
    const { selectedSprint, setSelectedSprint, setDates, sprints, setFilters } = useContext(DashboardContext);

    const [selectedLabel, setSelectedLabel] = React.useState<string>('');

    useEffect(() => {
        if (selectedSprint) {
            // Take the last 5 characters of the sprint's end date
            const date = selectedSprint.end_date.slice(-5);
            setSelectedLabel(date);
        }
    }, [selectedSprint]);
    if (!data || !data.labels || !data.datasets) {
        console.warn("El objeto `data` no está definido o tiene una estructura inválida:", data);
        return null; // O un mensaje de respaldo
      }
    
    // Define margins and the SVG's view box
    const margin = { top: 10, right: 40, bottom: 30, left: 60 };
    const chartWidth = width - margin.left - margin.right;
    const chartHeight = height - margin.top - margin.bottom;

    // Y-axis range
    const yMin = 0;
    const yMax = 100;

    // Function to map data points to SVG X-coordinates
    const xScale = (x: number) => (x / (data.labels.length - 1)) * chartWidth + margin.left;

    // Function to map data points to SVG Y-coordinates
    const yScale = (y: number) => chartHeight - ((y - yMin) / (yMax - yMin)) * chartHeight + margin.top;

    // Helper function to calculate control points for a smooth Catmull-Rom spline
    const getControlPoints = (i: number, values: number[]) => {
        const p0 = i > 0 ? values[i - 1] : values[0]; // Previous point or current if it's the first
        const p1 = values[i]; // Current point
        const p2 = i < values.length - 1 ? values[i + 1] : values[i]; // Next point or current if it's the last
        const p3 = i < values.length - 2 ? values[i + 2] : values[i + 1]; // Point after next or next if near the end

        const tension = 0.5; // Adjusts how "tight" the curve is
        const cp1x = xScale(i) + (xScale(i + 1) - xScale(i - 1)) / 6 * tension;
        const cp1y = yScale(p1) + (yScale(p2) - yScale(p0)) / 6 * tension;
        const cp2x = xScale(i + 1) - (xScale(i + 2) - xScale(i)) / 6 * tension;
        const cp2y = yScale(p2) - (yScale(p3) - yScale(p1)) / 6 * tension;

        return [cp1x, cp1y, cp2x, cp2y];
    };

    // Generate the path string for the Bezier line using Catmull-Rom splines
    const linePath = data.datasets[0].data.reduce((path, point, index, arr) => {
        const x = xScale(index);
        const y = yScale(point);

        if (index === 0) {
            return `M ${x},${y}`; // Start the path at the first point
        }

        // Get control points for smooth curves
        const [cp1x, cp1y, cp2x, cp2y] = getControlPoints(index - 1, arr);

        return `${path} C ${cp1x},${cp1y}, ${cp2x},${cp2y}, ${xScale(index)},${yScale(point)}`;
    }, '');

    return (
        <div>
            <div className='relative'>
                <svg width={width} height={height}>
                    {/* Vertical grid lines */}
                    {data.labels.map((label, index) => {
                        const strokeColor = label === selectedLabel ? "black" : "#F1F1F5";
                        const strokeWidth = label === selectedLabel ? "2.4" : "1.5";

                        return (
                            <line
                                key={index}
                                x1={xScale(index)}
                                y1={margin.top}
                                x2={xScale(index)}
                                y2={chartHeight + margin.top}
                                stroke={strokeColor}
                                strokeWidth={strokeWidth}
                            />
                        );
                    })}

                    {/* Y-axis labels */}
                    {verticalLabels.map((label, index) => {
                        const yPosition = chartHeight - (index / (verticalLabels.length - 1)) * chartHeight + margin.top;
                        return (
                            <text
                                key={index}
                                x={margin.left - 20}
                                y={yPosition}
                                textAnchor="end"
                                fontSize="10"
                                fill="gray"
                            >
                                {label}
                            </text>
                        );
                    })}

                    {/* X-axis labels */}
                    {data.labels.map((label, index) => (
                        <text
                            key={index}
                            x={xScale(index)}
                            y={chartHeight + margin.top + 20}
                            textAnchor="middle"
                            fontSize="10"
                            fill="gray"
                        >
                            {label}
                        </text>
                    ))}

                    {/* Lines and points */}
                    <g>
                        {data.datasets.map((dataset, datasetIndex) => {
                            const linePath = dataset.data.reduce((path, point, index, arr) => {
                                const x = xScale(index);
                                const y = yScale(point);

                                if (index === 0) {
                                    return `M ${x},${y}`;
                                }

                                const [cp1x, cp1y, cp2x, cp2y] = getControlPoints(index - 1, arr);

                                return `${path} C ${cp1x},${cp1y}, ${cp2x},${cp2y}, ${xScale(index)},${yScale(point)}`;
                            }, '');

                            return (
                                <g key={datasetIndex}>
                                    {/* Draw the line */}
                                    <path
                                        d={linePath}
                                        fill="none"
                                        stroke={dataset.borderColor}
                                        strokeWidth="3"
                                        strokeDasharray={dataset.borderDash?.join(", ") || "0"}
                                    />

                                    {/* Render points as circles */}
                                    {dataset.data.map((point, index) => (
                                        <circle
                                            key={index}
                                            cx={xScale(index)}
                                            cy={yScale(point)}
                                            r="5"
                                            fill={dataset.borderColor}
                                        />
                                    ))}
                                </g>
                            );
                        })}
                    </g>
                </svg>

                {/* Interactivity for data points */}
                {data.datasets.map((dataset, datasetIndex) => (
                    <div key={datasetIndex}>
                        {dataset.data.map((point, index) => (
                            <div
                                key={index}
                                onClick={() => {
                                    const selectedSprint_ = sprints.find((sprint: any) =>
                                        sprint.end_date.includes(data.labels[index])
                                    );
                                    setSelectedSprint(selectedSprint_);
                                    setDates(selectedSprint_.start_date, selectedSprint_.end_date);

                                    // Wait briefly before setting filters due to async state updates
                                    window.setTimeout(() => {
                                        setFilters([dataset.label.toLowerCase()]);
                                    }, 5);
                                }}
                                className="selectable"
                                style={{
                                    cursor: "pointer",
                                    borderRadius: "50%",
                                    width: 8,
                                    height: 8,
                                    backgroundColor: dataset.borderColor,
                                    position: "absolute",
                                    top: yScale(point) - 4,
                                    left: xScale(index) - 4,
                                    zIndex: 0,
                                }}
                            >
                                {/* Tooltip */}
                                <div className="tooltip">
                                    <b className="font-bold">{point}%</b>
                                    <p className="text-gray">{dataset.label}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                ))}
            </div>

            {/* Chart title */}
            <p className="text-gray-500 text-xs text-center mt-4">{chartName}</p>
        </div>
    );
};

export default Line;
