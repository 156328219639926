import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import groupOfThree from "../img/groupOfThree.svg";
import arrowUpGrey from "../img/arrow-up-grey.png"; 
import personMyStats from "../img/personMyStats.svg";

interface BottomNavigationProps {
  projectName: string;
  projectCount?: number; 
  selectedTab?: "My stats" | "Team";
  onSelectTab: (tab: "My stats" | "Team") => void;
  lineChartDataOpt?: any[] | undefined;
}

const BottomNavigation: React.FC<BottomNavigationProps> = ({
  projectName,
  projectCount = 1,
  selectedTab,
  onSelectTab,
  lineChartDataOpt = null,
}) => {
  const [isProjectOpen, setIsProjectOpen] = useState(false);
  const navigate = useNavigate();

  const toggleProjectDropdown = () => {
    setIsProjectOpen((prev) => !prev);
  };

  const handleTeamClick = () => {
    onSelectTab("Team");
    navigate("/dashboard/meVsTeam", { state: { lineChartData: lineChartDataOpt } });
  };

  return (
    <div
      className="flex justify-around items-center bg-white py-3"
      style={{
        position: "fixed",
        bottom: 0,
        width: "100%",
        maxWidth: "360px",
        margin: "0 auto",
        height: "58px",
        zIndex: 1000,
        borderRadius: "0px",
        marginTop: "20px",
      }}
    >
      {projectCount > 1 ? (
        <button
          className="flex items-center font-medium"
          style={{
            color: "#53565A",
            fontSize: "14px",
          }}
          onClick={toggleProjectDropdown}
        >
          <img
            src={arrowUpGrey}
            alt="Arrow Icon"
            width="10"
            height="10"
            style={{
              transform: isProjectOpen ? "rotate(180deg)" : "rotate(0deg)",
              transition: "transform 0.2s ease",
              marginRight: "8px",
            }}
          />
          {projectName}
        </button>
      ) : (
        <span
          className="font-medium"
          style={{
            color: "#53565A",
            fontSize: "14px",
          }}
        >
          {projectName}
        </span>
      )}

      {/* Buttons and dynamic icon */}
      <div className="flex items-center justify-center gap-6">
        {/* "My stats" button */}
        <button
          className={`flex flex-col items-center ${
            selectedTab === "My stats" ? "text-purple-700 font-bold" : "text-gray-600"
          }`}
          onClick={() => onSelectTab("My stats")}
          style={{
            fontSize: "14px",
            fontWeight: selectedTab === "My stats" ? "bold" : "normal",
            color: selectedTab === "My stats" ? "#6B4EFF" : "#53565A",
            whiteSpace: "nowrap",
          }}
        >
          My stats
        </button>

        {/* Central dynamic icon */}
        <div className="flex justify-center items-center">
          <img
            src={selectedTab === "My stats" ? personMyStats : groupOfThree} 
            alt={selectedTab === "My stats" ? "My Stats Icon" : "Team Icon"}
            width="24"
            height="24"
            style={{
              verticalAlign: "middle",
            }}
          />
        </div>

        {/* "Team" button */}
        <button
          className={`flex flex-col items-center ${
            selectedTab === "Team" ? "text-purple-700 font-bold" : "text-gray-600"
          }`}
          onClick={handleTeamClick}
          style={{
            fontSize: "14px",
            fontWeight: selectedTab === "Team" ? "bold" : "normal", 
            color: selectedTab === "Team" ? "#6B4EFF" : "#53565A",
            whiteSpace: "nowrap",
          }}
        >
          Team
        </button>
      </div>
    </div>
  );
};

export default BottomNavigation;
