import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import BottomNavigation from "../components/BottomNavigation";
import { DashboardContext } from "../context/DashboardContext";
import franjaNumMenuLeft from "../img/menu/franjaNumMenuLeft.svg";
import franjaNumMenuMiddle from "../img/menu/franjaNumMenuMiddle.svg";
import franjaNumMenuRight from "../img/menu/franjaNumMenuRight.svg";
import teamfloIcon from "../img/teamflo-icon.svg"; 


const Menu: React.FC = () => {
  const navigate = useNavigate();
  const { project } = useContext(DashboardContext);
  const token = localStorage.getItem("token"); 

  const handleRedirectToDashboard = () => {
    if (token) {
      navigate(`/dashboard?token=${token}`); 
    } else {
      console.error("No token disponible. Redirigiendo al inicio...");
      navigate("/");
    }
  };

  return (
    <div
      className="flex flex-col h-screen relative"
      style={{
        backgroundColor: "#492CBB",
        width: "100%",
        overflow: "hidden", 
      }}
    >
      {/* Header for the menu */}
      <div
        className="mx-auto flex items-center justify-between px-4"
        style={{
          width: 320,
          height: 50,
          marginTop: 20,
          backgroundColor: "rgba(255, 255, 255, 0.65)", 
          backdropFilter: "blur(10px)", 
          borderTopLeftRadius: "15px",
          borderTopRightRadius: "15px",
          fontFamily: "Poppins, sans-serif",
        }}
      >
        {/* Title with logo */}
        <div className="flex items-center" style={{ margin: 0, padding: 0 }}>
          <img
            src={teamfloIcon}
            alt="Teamflo logo"
            style={{
              width: 100,
              height: "auto",
            }}
          />
        </div>

        {/* Three "X" replacing circles */}
        <div className="flex space-x-2 items-center">
          <div
            className="cursor-pointer text-[#872B93] font-bold"
            style={{
              fontSize: 20,
            }}
            onClick={handleRedirectToDashboard}
          >
            ×
          </div>
          <div
            className="cursor-pointer text-[#AE70B6] font-bold"
            style={{
              fontSize: 20,
            }}
            onClick={handleRedirectToDashboard}
          >
            ×
          </div>
          <div
            className="cursor-pointer text-[#C99FCE] font-bold"
            style={{
              fontSize: 20,
            }}
            onClick={handleRedirectToDashboard}
          >
            ×
          </div>
        </div>
      </div>


      {/* Menu content */}
      <div
        className="flex flex-col items-center justify-center mx-auto p-4 rounded-xl"
        style={{
          width: 320,
          height: 224,
          backgroundColor: "rgba(214, 208, 240, 0.7)", 
          borderTopLeftRadius: "0px",
          borderTopRightRadius: "0px",
          borderBottomRightRadius: "12px",
          borderBottomLeftRadius: "12px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)",
        }}
      >
        <div className="flex flex-col w-full px-4 space-y-4">
          <button
            className="text-left font-bold text-white"
            style={{
              backgroundColor: "transparent",
              fontFamily: "Graphik, sans-serif",
              fontSize: 18,
              lineHeight: "28px",
              marginLeft: "0px", 
            }}
            onClick={() => navigate("/quick-guide")}
          >
            Quick Guide
          </button>
          <button
            className="text-left font-bold text-white"
            style={{
              backgroundColor: "transparent",
              fontFamily: "Graphik, sans-serif",
              fontSize: 18,
              lineHeight: "28px",
              marginLeft: "0px", 
            }}
            onClick={() => navigate("/about-us")}
          >
            About Us
          </button>
          <button
            className="text-left font-bold text-white"
            style={{
              backgroundColor: "transparent",
              fontFamily: "Graphik, sans-serif",
              fontSize: 18,
              lineHeight: "28px",
              marginLeft: "0px", 
            }}
            onClick={() => navigate("/contact-us")}
          >
            Contact Us
          </button>
        </div>
      </div>


      {/* Decorative SVG Line */}
      <svg
        width="360"
        height="282"
        viewBox="0 0 360 282"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          position: "absolute",
          bottom: "120px",
          left: 0,
          zIndex: 0,
        }}
      >
        <path
          d="M-197.882 232.173C-197.882 232.173 -123.306 330.3 -54.6177 233.717C14.0811 137.14 -4.48716 45.715 125.255 173.427C254.989 301.141 254.152 190.547 258.17 120.359C262.187 50.1704 266.468 -33.7038 400.16 25.0446"
          stroke="#A391E9"
          strokeWidth="10"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
      </svg>
      {/* Decorative numbers middle */}
      <img
        src={franjaNumMenuMiddle}
        alt="Decorative numbers middle"
        style={{
          position: "absolute",
          top: "480px",
          left: "45%",
          transform: "translateX(-50%)",
          zIndex: 1,
          width: "180px", 
          height: "160px",
        }}
      />

      {/* Decorative numbers line */}
      <img
        src={franjaNumMenuLeft}
        alt="Decorative numbers left"
        style={{
          position: "absolute",
          bottom: 60,
          left: 30,
          zIndex: 1,
          width: "137px", 
          height: "169px", 
        }}
      />
      {/* Decorative numbers right */}
      <img
        src={franjaNumMenuRight}
        alt="Decorative numbers right"
        style={{
          position: "absolute",
          bottom: 260,
          right: 6,
          zIndex: 1,
          width: "100px", 
          height: "87px", 
        }}
      />

      {/* Bottom Navigation */}
      <div className="absolute bottom-0 w-full flex justify-center">
        <div className="w-[360px]">
          <BottomNavigation
            projectName={project?.name || ""}
            projectCount={project?.length || 1}
            onSelectTab={(tab) => console.log(`Selected tab: ${tab}`)}
          />
        </div>
      </div>
    </div>
  );
};

export default Menu;
