import React, { useContext, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Header from "../components/header";
import SprintHistory from "../components/sprintHistory";
import BottomNavigation from "../components/BottomNavigation";
import LineChart from "../components/lineChart";
import InsightManager from "../manager/insightManager";
import Ring from "../components/ring";
import { sentiment_rgb, caring_rgb, engagement_rgb } from "../config";
import { DashboardContext } from "../context/DashboardContext";
import InsightTeam from "../components/insightTeam"; 
import starColorImg from "../img/star-color.png";
import heartColorImg from "../img/heart-color.png";
import emojiColorImg from "../img/emoji_color_5.png";


const MeVsTeam: React.FC = (props) => {
  const { sprints, project, filters, insights } = useContext(DashboardContext);
  const navigate = useNavigate();
  const location = useLocation();
  // console.log("Datos del proyecto desde el contexto:", project);
  
  
  const [filteredInsights, setFilteredInsights] = useState<any[]>([]);
  const [lineChartData, setLineChartData] = useState<any[]>(location.state?.lineChartData);
  const [selectedSprint, setSelectedSprint] = useState<any>();
  useEffect(() => {
    if (project && sprints.length > 0) {
      const recentSprint = sprints[sprints.length - 1];
      setSelectedSprint(recentSprint);
    }
  }, [project, sprints]);

  useEffect(() => {
    if (selectedSprint) {
      const filtered = lineChartData.filter((insight: any) => {
        const start = new Date(insight.date_start);
        const end = new Date(insight.date_end);
        const sprintStart = new Date(selectedSprint.start_date);
        const sprintEnd = new Date(selectedSprint.end_date);
  
        return start >= sprintStart && end <= sprintEnd;
      });
  
      setFilteredInsights(filtered);
    }
  }, [selectedSprint, lineChartData]);
  


  
  const sentimentIndividual = InsightManager.GetLatest(lineChartData, "sentiment_individual")?.data?.value || 0;
  const caringIndividual = InsightManager.GetLatest(lineChartData, "caring_individual")?.data?.value || 0;
  const engagementIndividual = InsightManager.GetLatest(lineChartData, "engagement_individual")?.data?.value || 0;
  const overalIndividual = InsightManager.GetLatest(lineChartData, "overal_individual")?.data?.value || 0;


  const getValue = (data: any[], type: string): number => {
    const filtered = data.filter((item) => item.insight_type === type);
    if (filtered.length === 0) return 0;
  
    const latest = filtered[filtered.length - 1];
  
    try {
      if (typeof latest.data === "string") {
  
        const cleanedData = latest.data.replace(/'/g, '"'); 
        const parsed = JSON.parse(cleanedData);
        return parsed.value || 0;
      }
  
      if (typeof latest.data === "object" && latest.data.value !== undefined) {
        return latest.data.value;
      }
    } catch (error) {
      console.error(`Error al procesar los datos para ${type}:`, latest.data, error);
    }
  
    return 0;
  };
  
    const sentimentTeam = getValue(lineChartData, "sentiment_team");
    const caringTeam = getValue(lineChartData, "caring_team");
    const engagementTeam = getValue(lineChartData, "engagement_team");
    const overalTeam = getValue(lineChartData, "overall_team");


    const generateInsightMessage = (type: string, individual: number, team: number): string => {
      if (type === "engagement") {
        if (individual > team + 10) return "You're outperforming the team in engagement!";
        if (Math.abs(individual - team) <= 10) return "Your engagement is in line with your team.";
        return "Consider focusing on improving your engagement to match your team.";
      }
    
      if (type === "caring") {
        if (individual > team + 10) return "You're showing great care for the team!";
        if (Math.abs(individual - team) <= 10) return "Your caring score aligns with your team.";
        return "Try connecting more with your team to improve your caring score.";
      }
    
      if (type === "sentiment") {
        if (individual > team + 10) return "Your sentiment is outstanding! Keep spreading positivity.";
        if (Math.abs(individual - team) <= 10) return "Your sentiment is in line with your team.";
        return "Focus on improving your sentiment for a better team atmosphere.";
      }
    
      return ""; // Fallback si el tipo no coincide
    };
    
    // MOCK DATA para insights del equipo basado en comparación Individual vs Equipo
    const teamInsightsMock = [
      {
        icon: starColorImg,
        message: generateInsightMessage("engagement", engagementIndividual, engagementTeam),
        color: "rgba(222, 122, 4, 0.3)",
        type: "engagement",
      },
      {
        icon: heartColorImg,
        message: generateInsightMessage("caring", caringIndividual, caringTeam),
        color: "rgba(135, 43, 147, 0.3)",
        type: "caring",
      },
      {
        icon: emojiColorImg,
        message: generateInsightMessage("sentiment", sentimentIndividual, sentimentTeam),
        color: "rgba(48, 180, 187, 0.3)",
        type: "sentiment",
      },
    ];
    
    const sanitizeJSON = (data: any[]): any[] => {
      return data.map(item => {
        const sanitizedItem = JSON.stringify(item)
          .replace(/\\/g, '\\\\')  // Escape backslashes
          .replace(/'/g, "\\'")    // Escape single quotes
          .replace(/"/g, '\\"');   // Escape double quotes
        return JSON.parse(sanitizedItem);
      });
    };

  return (
    <div className="me-vs-team-page">
      {/* Header */}
      <Header showBackground={true} />

      {/* Sprint History */}
      <div className="sprint-history-container">
        <SprintHistory sprints={sprints} />
      </div>

      {/* Team Performance */}
      <div
        className="bg-white rounded-xl mt-6 w-full max-w-md"
        style={{ maxWidth: 360, marginTop: "30px", paddingTop: "12px" }}
      >
        <h4
          className="font-semibold"
          style={{
            fontFamily: "Graphik, sans-serif",
            fontSize: "14px",
            fontWeight: 600,
            color: "#53565A",
            lineHeight: "15.4px",
            marginBottom: "12px",
            padding: "24px",
          }}
        >
          Me and My Team
        </h4>
        <div className="flex flex-row justify-center space-x-16">
          {/* Ring for You */}
          <div className="flex flex-col items-center">
            <div className="RingContainer w-[110px] h-[110px] flex flex-col items-center">
            <Ring
              color={sentiment_rgb}
              progress={sentimentIndividual}
              strokeWidth={2.8}
              size={40}
              scale={1.43}
              filter={"sentiment"}
              showCenterValue={filters.includes("sentiment")}
          />
          <Ring
              color={caring_rgb}
              progress={caringIndividual}
              strokeWidth={3.5}
              size={40}
              scale={1.1}
              filter={"caring"}
              showCenterValue={filters.includes("caring")}
          />
          <Ring
              color={engagement_rgb}
              progress={engagementIndividual}
              strokeWidth={5}
              size={40}
              scale={0.77}
              filter={"engagement"}
              showCenterValue={filters.includes("engagement")}
          />

              {(filters.length === 0 || filters.length > 1) && (
                <p className="text-xl absolute left-[50%] top-[50%] translate-x-[-40%] translate-y-[-50%] text-light-gray font-bold">
                  {overalIndividual}%
                </p>
              )}
            </div>
            <p
              className="text-center font-medium mt-5"
              style={{
                color: "#92929D",
                fontSize: "12px",
                fontWeight: "500",
                lineHeight: "13.2px",
              }}
            >
              You
            </p>
          </div>

          {/* Ring for Team */}
          <div className="flex flex-col items-center">
            <div className="RingContainer w-[110px] h-[110px] flex flex-col items-center">
            <Ring
              color={sentiment_rgb}
              progress={sentimentTeam}
              strokeWidth={2.8}
              size={40}
              scale={1.43}
              filter={"sentiment"}
              showCenterValue={filters.includes("sentiment")}
          />
          <Ring
              color={caring_rgb}
              progress={caringTeam}
              strokeWidth={3.5}
              size={40}
              scale={1.1}
              filter={"caring"}
              showCenterValue={filters.includes("caring")}
          />
          <Ring
              color={engagement_rgb}
              progress={engagementTeam}
              strokeWidth={5}
              size={40}
              scale={0.77}
              filter={"engagement"}
              showCenterValue={filters.includes("engagement")}
          />
              {(filters.length === 0 || filters.length > 1) && (
                <p className="text-xl absolute left-[50%] top-[50%] translate-x-[-40%] translate-y-[-50%] text-light-gray font-bold">
                  {overalTeam}%
                </p>
              )}
            </div>
            <p
              className="text-center font-medium mt-5"
              style={{
                color: "#92929D",
                fontSize: "12px",
                fontWeight: "500",
                lineHeight: "13.2px",
              }}
            >
              {project?.name || "Team"}
            </p>
          </div>
        </div>
      </div>

      {/* Team Insights */}
      <InsightTeam
        insights={teamInsightsMock}
        title="SPRINT"
        filters={filters} 
      />

      {/* Line Chart */}
      <div
        className="bg-white rounded-xl mt-6 p-6 w-full max-width-md"
        style={{ maxWidth: 360 }}
      >
        <LineChart 
            insights={lineChartData}
            
            showTeamMetrics={true} 
             />
      </div>

      {/* Bottom Navigation */}
      <BottomNavigation
        projectName={project?.name || "Project"}
        selectedTab={"Team"}
        onSelectTab={() => {
          navigate("/dashboard");
        }}
      />
    </div>
  );
};

export default MeVsTeam;
