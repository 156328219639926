import React, { useState } from "react";
import Onboarding01 from "./onboarding_01";
import Onboarding02 from "./onboarding_02";
import Onboarding03 from "./onboarding_03";
import Onboarding04 from "./onboarding_04";
import Onboarding05 from "./onboarding_05";
import Onboarding06 from "./onboarding_06";
import Onboarding07 from "./onboarding_07";

const QuickGuide: React.FC = () => {
  const [step, setStep] = useState(0);
  const totalSteps = 7; 

  const nextStep = () => {
    setStep((prev) => (prev < totalSteps - 1 ? prev + 1 : prev));
  };

  const prevStep = () => {
    setStep((prev) => (prev > 0 ? prev - 1 : prev));
  };

  const renderStep = () => {
    const showNavigationBar = step !== 0; 
    switch (step) {
      case 0:
        return <Onboarding01 nextStep={nextStep} showNavigationBar={showNavigationBar} />;
      case 1:
        return <Onboarding02 nextStep={nextStep} prevStep={prevStep} showNavigationBar={showNavigationBar} />;
      case 2:
        return <Onboarding03 nextStep={nextStep} prevStep={prevStep} currentStep={step} showNavigationBar={showNavigationBar} />;
      case 3:
        return <Onboarding04 nextStep={nextStep} prevStep={prevStep} currentStep={step} showNavigationBar={showNavigationBar} />;
      case 4:
        return <Onboarding05 nextStep={nextStep} prevStep={prevStep} currentStep={step} showNavigationBar={showNavigationBar} />;
      case 5:
        return <Onboarding06 nextStep={nextStep} prevStep={prevStep} currentStep={step} showNavigationBar={showNavigationBar} />;
      case 6:
        return <Onboarding07 nextStep={nextStep} prevStep={prevStep} currentStep={step} showNavigationBar={showNavigationBar} />;
      default:
        return <Onboarding01 nextStep={nextStep} showNavigationBar={showNavigationBar} />;
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        height: "100vh",
        backgroundColor: "#F5F3FF",
        padding: "0px",
        position: "relative",
        overflow: "hidden",
      }}
    >

      <div style={{ flex: 1, width: "100%" }}>{renderStep()}</div>

      {step !== 0 && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            maxWidth: "300px",
            zIndex: 1,
            marginTop: "auto",
            paddingBottom: "16px",
          }}
        >
          <button
            onClick={prevStep}
            disabled={step === 0}
            style={{
              border: "none",
              backgroundColor: "transparent",
              fontSize: "24px",
              color: step === 0 ? "#D0C1FF" : "#6A4DF8",
              cursor: step === 0 ? "not-allowed" : "pointer",
            }}
          >
            &lt;
          </button>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "8px",
            }}
          >
            {Array.from({ length: totalSteps }).map((_, index) => (
              <div
                key={index}
                style={{
                  width: index === step ? "24px" : "8px",
                  height: "8px",
                  borderRadius: index === step ? "4px" : "50%",
                  backgroundColor: index === step ? "#6A4DF8" : "#D0C1FF",
                  transition: "all 0.3s ease",
                }}
              ></div>
            ))}
          </div>
          <button
            onClick={nextStep}
            disabled={step === totalSteps - 1}
            style={{
              border: "none",
              backgroundColor: "transparent",
              fontSize: "24px",
              color: step === totalSteps - 1 ? "#D0C1FF" : "#6A4DF8",
              cursor: step === totalSteps - 1 ? "not-allowed" : "pointer",
            }}
          >
            &gt;
          </button>
        </div>
      )}
    </div>
  );
};

export default QuickGuide;
