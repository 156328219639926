import React from "react"

const emptyHeart = require("../img/heart-gray.png");
const fullHeart = require("../img/heart-color.png");
const emptyStar = require("../img/star-gray.png");
const fullStar = require("../img/star-color.png");

const emojiEmpty1 = require("../img/emoji_gray_1.png");
const emojiEmpty2 = require("../img/emoji_gray_2.png");
const emojiEmpty3 = require("../img/emoji_gray_3.png");
const emojiEmpty4 = require("../img/emoji_gray_4.png");
const emojiEmpty5 = require("../img/emoji_gray_5.png");

const emojiFull1 = require("../img/emoji_color_1.png");
const emojiFull2 = require("../img/emoji_color_2.png");
const emojiFull3 = require("../img/emoji_color_3.png");
const emojiFull4 = require("../img/emoji_color_4.png");
const emojiFull5 = require("../img/emoji_color_5.png");

const empties:any = {
    "caring_individual":[emptyHeart, emptyHeart, emptyHeart, emptyHeart, emptyHeart],
    "engagement_individual":[emptyStar, emptyStar, emptyStar, emptyStar, emptyStar],
    "sentiment_individual":[emojiEmpty1, emojiEmpty2, emojiEmpty3, emojiEmpty4, emojiEmpty5]
}

const full:any = {
    "caring_individual":[fullHeart, fullHeart, fullHeart, fullHeart, fullHeart],
    "engagement_individual":[fullStar, fullStar, fullStar, fullStar, fullStar],
    "sentiment_individual": [emojiFull1, emojiFull2, emojiFull3, emojiFull4, emojiFull5]
}

interface EmojiBarProps{
    insight: any;
}

const imgClass = "mx-2 w-[36px] h-auto";

const EmojiBar: React.FC<EmojiBarProps> = ({insight}) => {

    //returns the icon either filled or empty based on the value
    function icon(v:number, i:number){
        
        
        if(insight.insight_type === "sentiment_individual"){
            if(insight.data.value > 89){
                insight.data.value = 100;
            }
            var a = Math.floor(insight.data.value/25);
            if(a===i){
                return full[insight.insight_type][i]
            }else{
                return empties[insight.insight_type][i]
            }
        }
        
        return insight.data.value > v ? full[insight.insight_type][i] : empties[insight.insight_type][i]
    }

    return (
        <div className="flex flex-row justify-start pl-4 mb-4">
            <img src={icon(0,0)} className={imgClass} alt=""  />
            <img src={icon(20,1)} className={imgClass} alt=""  />
            <img src={icon(40,2)} className={imgClass} alt=""  />
            <img src={icon(75,3)} className={imgClass} alt=""  />
            <img src={icon(90,4)} className={imgClass} alt=""  />
        </div>
    )
}

export default EmojiBar;