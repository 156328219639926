import React from "react";
import { useNavigate } from "react-router-dom";
import ringOnboarding2 from "../../img/quickGuide/ringOnboarding2.svg";
import onboardingDetailsUpPage1 from "../../img/quickGuide/onboardingDetailsUpPage1.svg";
import onboardingDetailsDownPage1 from "../../img/quickGuide/onboardingDetailsDownPage1.svg";

interface OnboardingProps {
  nextStep: () => void;
  prevStep: () => void;
  currentStep: number;
  showNavigationBar?: boolean;
}

const Onboarding03: React.FC<OnboardingProps> = ({ nextStep, prevStep, currentStep, showNavigationBar }) => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        height: "100vh",
        backgroundColor: "#F5F3FF",
        padding: "32px",
        position: "relative",
        textAlign: "center",
        overflow: "hidden",
      }}
    >
      <img
        src={onboardingDetailsUpPage1}
        alt="Background Wave Up"
        style={{
          position: "absolute",
          top: "0px",
          left: "0px",
          width: "200%",
          height: "auto",
          zIndex: 0,
          opacity: 0.75,
        }}
      />

      <img
        src={onboardingDetailsDownPage1}
        alt="Background Wave Down"
        style={{
          position: "absolute",
          bottom: "0px",
          right: "0px",
          width: "200%",
          height: "auto",
          zIndex: 0,
          opacity: 0.75,
          transform: "rotate(0)",
        }}
      />

      <button
        onClick={() => navigate("/dashboard/menu")}
        style={{
          position: "absolute",
          top: "16px",
          right: "16px",
          fontSize: "16px",
          fontWeight: "bold",
          color: "#6A4DF8",
          background: "none",
          border: "none",
          cursor: "pointer",
          zIndex: 1,
        }}
      >
        skip
      </button>

      <h1
        style={{
          fontSize: "24px",
          fontWeight: 700,
          color: "#2E1F57",
          fontFamily: "Graphik, sans-serif",
          lineHeight: "32px",
          marginBottom: "16px",
          zIndex: 1,
        }}
      >
        Discover how you support and connect with your team.
      </h1>

      <div
        style={{
          width: "90%",
          maxWidth: "350px",
          backgroundColor: "#FFFFFF",
          borderRadius: "16px",
          padding: "16px",
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "-48px",
          zIndex: 1,
        }}
      >
        <div
          style={{
            width: "250px",
            height: "250px",
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >

          <img
            src={ringOnboarding2}
            alt="Caring Ring"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
            }}
          />
        </div>
      </div>

      <div
        style={{
          backgroundColor: "#FFFFFF",
          borderRadius: "16px",
          padding: "16px",
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
          marginBottom: "0px",
          marginTop: "0",
          width: "90%",
          maxWidth: "350px",
          textAlign: "center",
          zIndex: 1,
        }}
      >
        <h2
          style={{
            fontSize: "20px",
            fontWeight: 700,
            color: "#6A4DF8",
            marginBottom: "0px",
          }}
        >
          CARING
        </h2>
        <p
          style={{
            fontSize: "14px",
            fontWeight: 400,
            color: "#2E1F57",
          }}
        >
          Understand how you support and connect with your team.
        </p>
      </div>

      <p
        style={{
          fontSize: "14px",
          fontWeight: 400,
          color: "#2E1F57",
          marginBottom: "32px",
          zIndex: 1,
        }}
      >
        Each ring brings insights to help you track and improve your experience
        and impact.
      </p>

      {/* nav bar */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          maxWidth: "300px",
          zIndex: 1,
        }}
      >
        <button
          onClick={prevStep}
          style={{
            border: "none",
            backgroundColor: "transparent",
            fontSize: "24px",
            color: "#6A4DF8",
            cursor: "pointer",
          }}
        >
          &lt;
        </button>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "8px",
          }}
        >
          {Array(7) 
            .fill(null)
            .map((_, index) => (
              <div
                key={index}
                style={{
                  width: index === currentStep ? "24px" : "8px", 
                  height: "8px",
                  borderRadius: index === currentStep ? "4px" : "50%",
                  backgroundColor: index === currentStep ? "#6A4DF8" : "#D0C1FF",
                  transition: "all 0.3s ease",
                }}
              ></div>
            ))}
        </div>
        <button
          onClick={nextStep}
          style={{
            border: "none",
            backgroundColor: "transparent",
            fontSize: "24px",
            color: "#6A4DF8",
            cursor: "pointer",
          }}
        >
          &gt;
        </button>
      </div>
    </div>
  );
};

export default Onboarding03;
