import React, { useContext } from "react";
import Header from "../components/header";
import { DashboardContext } from "../context/DashboardContext";
import { useNavigate } from "react-router-dom";
const backArrow = require("../img/back-arrow.png");

const AchievementsPage: React.FC = () => {
    const navigate = useNavigate();
    const { badges } = useContext(DashboardContext);
    // console.log("Badges from context:", badges);

    const allPossibleBadges = [
        { badge_type: "caring_individual", description: "above 80%" },
        { badge_type: "caring_individual", description: "above 90%" },
        { badge_type: "sentiment_individual", description: "above 80%" },
        { badge_type: "sentiment_individual", description: "above 90%" },
        { badge_type: "engagement_individual", description: "above 80%" },
        { badge_type: "engagement_individual", description: "above 90%" },
        { badge_type: "caring_frequency", description: "2 sprints in a row above 80%" },
        { badge_type: "caring_frequency", description: "2 sprints in a row above 90%" },
        { badge_type: "engagement_frequency", description: "2 sprints in a row above 80%" },
        { badge_type: "engagement_frequency", description: "2 sprints in a row above 90%" },
        { badge_type: "sentiment_frequency", description: "2 sprints in a row above 80%" },
        { badge_type: "sentiment_frequency", description: "2 sprints in a row above 90%" },
        { badge_type: "all_rounder", description: "all categories above 80%" },
        { badge_type: "all_rounder", description: "all categories above 90%" },
    ];

    // Agrupamos los badges por tipo y nivel para contar los ganados
    const groupBadges = (badges: any[]) => {
        const grouped = badges.reduce((acc: { [key: string]: any }, badge) => {
            const level = badge.description.includes("90%") ? "90" : "80";
            const frequency = badge.streak && badge.streak > 1 ? "_2" : "";
            const key = `${badge.badge_type}_${level}${frequency}`;
            if (!acc[key]) {
                acc[key] = { ...badge, count: 1 };
            } else {
                acc[key].count += 1;
            }
            return acc;
        }, {});
        return grouped;
    };

    const groupedBadges = groupBadges(badges);

    const mapBadgeType = (badgeType: string): string => {
        const mapping: { [key: string]: string } = {
            caring_individual: "caring",
            sentiment_individual: "sent",
            engagement_individual: "eng",
            caring_frequency: "caring",
            engagement_frequency: "eng",
            sentiment_frequency: "sent",
            all_rounder: "all",
        };
        return mapping[badgeType] || badgeType;
    };

    const getBadgeImage = (badge: any): any => {
        const level = badge.description.includes("90%") ? "90" : "80";
        const frequency = badge.badge_type.includes("frequency") ? "_2" : "";
        const badgeType = mapBadgeType(badge.badge_type);

        try {
            return require(`../img/badges/badge_${badgeType}_${level}${frequency}.png`);
        } catch (error) {
            console.error("Error loading image for badge:", badge);
            return require("../img/badges/sentiment_empty.png");
        }
    };

    const handleBadgeClick = (badge: any) => {
        const badgeHistory = badges.filter(b => b.badge_type === badge.badge_type);

        navigate("/dashboard/badgedetails", {
            state: { badge, history: badgeHistory },
        });
    };

    return (
        <div className="achievements-page-container flex flex-col mx-auto items-center" style={{ maxWidth: 360 }}>
            <div
                className="w-full px-4"
                style={{
                    marginBottom: "-16px",
                    zIndex: 10,
                    position: "relative",
                    marginTop: "-12px",
                }}
            >
                <button
                    onClick={() => navigate(-1)}
                    className="text-purple-600 text-lg flex items-center"
                    style={{ position: "relative", top: "90px" }}
                >
                    <img
                        src={backArrow}
                        alt="Back"
                        style={{ width: "24.5px", height: "14px", marginRight: "10px", marginLeft: "10px" }}
                    />
                    Badges
                </button>
            </div>

            <Header showBackground={true} />
            <h2
                className="font-bold text-left text-2xl mt-6 w-full px-6"
                style={{ color: "#0E0E2C", fontSize: "22px", fontWeight: "500", lineHeight: "22px", marginTop: "28px" }}
            >
                Check all
            </h2>

            {/* Score */}
            <div className="bg-white rounded-xl mt-6 p-4 w-full">
                <h4
                    className="font-bold text-left mb-4"
                    style={{
                        color: "#53565A",
                        fontSize: "14px",
                        fontWeight: "600",
                        lineHeight: "15.4px",
                        marginLeft: "16px",
                        marginTop: "18px",
                    }}
                >
                    Score
                </h4>
                <div className="flex flex-row flex-wrap">
                    {allPossibleBadges
                        .filter(b =>
                            ["caring_individual", "sentiment_individual", "engagement_individual"].includes(b.badge_type)
                        )
                        .map((badge, index) => {
                            const level = badge.description.includes("90%") ? "90" : "80";
                            const key = `${badge.badge_type}_${level}`;
                            const isEarned = groupedBadges[key];

                            return (
                                <div
                                    key={index}
                                    onClick={() => isEarned && handleBadgeClick(badge)}
                                    className={`w-[30%] mr-[3%] mt-4 cursor-pointer relative flex flex-col items-center ${!isEarned ? 'greyed-out' : ''}`}
                                >
                                    <img className="w-full" src={getBadgeImage(badge)} alt={badge.badge_type} />
                                    <p className="text-center text-dark">{badge.badge_type.replace("_individual", "")}</p>
                                    <p className="text-center text-gray-500 text-sm">{badge.description}</p>
                                    {isEarned && isEarned.count > 1 && (
                                        <span className="badge-score-counter-achievements">{isEarned.count}</span>
                                    )}
                                </div>
                            );
                        })}
                </div>
            </div>

            {/* Frequency */}
            <div className="bg-white rounded-xl mt-6 p-4 w-full">
                <h4
                    className="font-bold text-left mb-4"
                    style={{
                        color: "#53565A",
                        fontSize: "14px",
                        fontWeight: "600",
                        lineHeight: "15.4px",
                        marginLeft: "16px",
                        marginTop: "18px",
                    }}
                >
                    Frequency
                </h4>
                <div className="flex flex-row flex-wrap">
                    {allPossibleBadges
                        .filter(b =>
                            ["caring_frequency", "engagement_frequency", "sentiment_frequency"].includes(b.badge_type)
                        )
                        .map((badge, index) => {
                            const level = badge.description.includes("90%") ? "90" : "80";
                            const frequency = "_2";
                            const key = `${badge.badge_type}_${level}${frequency}`;
                            const isEarned = groupedBadges[key];

                            return (
                                <div
                                    key={index}
                                    onClick={() => isEarned && handleBadgeClick(badge)}
                                    className={`w-[30%] mr-[3%] mt-4 cursor-pointer relative flex flex-col items-center ${!isEarned ? 'greyed-out' : ''}`}
                                >
                                    <img className="w-full" src={getBadgeImage(badge)} alt={badge.badge_type} />
                                    <p className="text-center text-dark">{badge.badge_type.replace("_frequency", "")}</p>
                                    <p className="text-center text-gray-500 text-sm">{badge.description}</p>
                                    {isEarned && (
                                        <span className="badge-score-counter-achievements">{isEarned.count}</span>
                                    )}
                                </div>
                            );
                        })}
                </div>
            </div>

            {/* All Categories */}
            <div className="bg-white rounded-xl mt-6 p-4 w-full">
                <h4
                    className="font-bold text-left mb-4"
                    style={{
                        color: "#53565A",
                        fontSize: "14px",
                        fontWeight: "600",
                        lineHeight: "15.4px",
                        marginLeft: "16px",
                        marginTop: "18px",
                    }}
                >
                    All Categories
                </h4>
                <div className="flex flex-row flex-wrap">
                    {allPossibleBadges
                        .filter(b => b.badge_type === "all_rounder")
                        .map((badge, index) => {
                            const level = badge.description.includes("90%") ? "90" : "80";
                            const key = `${badge.badge_type}_${level}`;
                            const isEarned = groupedBadges[key];

                            return (
                                <div
                                    key={index}
                                    onClick={() => isEarned && handleBadgeClick(badge)}
                                    className={`w-[30%] mr-[3%] mt-4 cursor-pointer relative flex flex-col items-center ${!isEarned ? 'greyed-out' : ''}`}
                                >
                                    <img className="w-full" src={getBadgeImage(badge)} alt={badge.badge_type} />
                                    <p className="text-center text-dark">{badge.badge_type.replace("_individual", "")}</p>
                                    <p className="text-center text-gray-500 text-sm">{badge.description}</p>
                                    {isEarned && (
                                        <span className="badge-score-counter-achievements">{isEarned.count}</span>
                                    )}
                                </div>
                            );
                        })}
                </div>
            </div>
        </div>
    );
};

export default AchievementsPage;

