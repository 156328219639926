import React from "react";

type CounterBadgesProps = {
    totalBadges: number;
    engagementCount80: number;
    engagementCount90: number;
    caringCount80: number;
    caringCount90: number;
    sentimentCount80: number;
    sentimentCount90: number;
};

const backgrounds = {
    engagement: require("../img/counterCicleEng.png"),
    caring: require("../img/counterCicleCaring.png"),
    sentiment: require("../img/counterCicleSent.png"),
};

const icons = {
    engagement: require("../img/star-gray.png"), 
    caring: require("../img/heart-gray.png"), 
    sentiment: require("../img/emoji_gray_5.png"), 
};

const CounterBadges: React.FC<CounterBadgesProps> = ({
    totalBadges,
    engagementCount80,
    engagementCount90,
    caringCount80,
    caringCount90,
    sentimentCount80,
    sentimentCount90
}) => {
    return (
        <div className="bg-white rounded-xl mt-6 p-4 w-full" >
            <div className="text-center">
                <h2 className="font-medium text-[24px] leading-[26px] text-[#0E0E2C] mb-4 text-center">
                    You've earned <br /> {totalBadges} Badges
                </h2>
                <div className="flex justify-center space-x-6 mt-4">
                    <div className="flex flex-col items-center">
                        <div className="badge-background" style={{ backgroundColor: "#DE7A04", width: "66px", height: "66px", position: "relative" }}>
                            <img src={icons.engagement} alt="Engagement icon" className="w-[30px] h-[30px] filter-white" />
                            <span className="badge-counter-circle" style={{
                                border: "2px solid #DE7A04",
                                color: "#DE7A04",
                                position: "absolute",
                                top: "-4px",
                                right: "-4px",
                                width: "22px",
                                height: "22px"
                            }}>
                                {engagementCount80 + engagementCount90}
                            </span>
                        </div>
                        <span className="badge-text">Engagement</span>
                    </div>
    
                    <div className="flex flex-col items-center">
                        <div className="badge-background" style={{ backgroundColor: "#872B93", width: "66px", height: "66px", position: "relative" }}>
                            <img src={icons.caring} alt="Caring icon" className="w-[30px] h-[30px] filter-white" />
                            <span className="badge-counter-circle" style={{
                                border: "2px solid #872B93",
                                color: "#872B93",
                                position: "absolute",
                                top: "-4px",
                                right: "-4px",
                                width: "22px",
                                height: "22px"
                            }}>
                                {caringCount80 + caringCount90}
                            </span>
                        </div>
                        <span className="badge-text">Caring</span>
                    </div>
    
                    <div className="flex flex-col items-center">
                        <div className="badge-background" style={{ backgroundColor: "#30B4BC", width: "66px", height: "66px", position: "relative" }}>
                            <img src={icons.sentiment} alt="Sentiment icon" className="w-[30px] h-[30px] filter-white" />
                            <span className="badge-counter-circle" style={{
                                border: "2px solid #30B4BC",
                                color: "#30B4BC",
                                position: "absolute",
                                top: "-4px",
                                right: "-4px",
                                width: "22px",
                                height: "22px"
                            }}>
                                {sentimentCount80 + sentimentCount90}
                            </span>
                        </div>
                        <span className="badge-text">Sentiment</span>
                    </div>
                </div>
            </div>
        </div>
    );
    
};

export default CounterBadges;
