import React, { useContext } from "react";
import Header from "../components/header";
import CounterBadges from "../components/counterBadges";
import { DashboardContext } from "../context/DashboardContext";
import { useNavigate } from "react-router-dom";

const backArrow = require("../img/back-arrow.png");

type Badge = {
    badge_type: string;
    name: string;
    description: string;
    awarded_date: string;
    streak?: number;
    count?: number;
};

const MyBadgesPage: React.FC = () => {
    const navigate = useNavigate();
    const { badges } = useContext(DashboardContext);

    const groupBadges = (badges: Badge[]): Badge[] => {
        const grouped = badges.reduce((acc: { [key: string]: Badge }, badge: Badge) => {
            const key = `${badge.badge_type}_${badge.description}`;
            if (!acc[key]) {
                acc[key] = { ...badge, count: 1 };
            } else {
                acc[key].count = (acc[key].count || 1) + 1;
            }
            return acc;
        }, {});
        return Object.values(grouped);
    };

    const scoreBadges = groupBadges(badges.filter((badge) =>
        ["caring_individual", "sentiment_individual", "engagement_individual"].includes(badge.badge_type)
    ));

    //console.log("scoreBadges:", scoreBadges); 

    const frequencyBadges = groupBadges(badges.filter((badge) => badge.streak && badge.streak > 1));
    //console.log("frequencyBadges:", frequencyBadges); 

    const allCategoriesBadges: Badge[] = groupBadges(
        badges.filter((badge) => badge.badge_type === "all_rounder")
    );
    //console.log("allCategoriesBadges:", allCategoriesBadges); 

    const engagementCount80 = badges.filter(badge => 
        badge.badge_type === "engagement_individual" && badge.description.includes("80%")
    ).length;

    const engagementCount90 = badges.filter(badge => 
        badge.badge_type === "engagement_individual" && badge.description.includes("90%")
    ).length;

    const caringCount80 = badges.filter(badge => 
        badge.badge_type === "caring_individual" && badge.description.includes("80%")
    ).length;

    const caringCount90 = badges.filter(badge => 
        badge.badge_type === "caring_individual" && badge.description.includes("90%")
    ).length;

    const sentimentCount80 = badges.filter(badge => 
        badge.badge_type === "sentiment_individual" && badge.description.includes("80%")
    ).length;

    const sentimentCount90 = badges.filter(badge => 
        badge.badge_type === "sentiment_individual" && badge.description.includes("90%")
    ).length;

    // console.log("Counts - Engagement 80%:", engagementCount80, "Engagement 90%:", engagementCount90);
    // console.log("Counts - Caring 80%:", caringCount80, "Caring 90%:", caringCount90);
    // console.log("Counts - Sentiment 80%:", sentimentCount80, "Sentiment 90%:", sentimentCount90);

    const handleBadgeClick = (badge: Badge) => {
        const badgeHistory = badges.filter((b) => b.badge_type === badge.badge_type);
        navigate("/dashboard/badgedetails", {
            state: { badge, history: badgeHistory },
        });
    };

    const mapBadgeType = (badgeType: string): string => {
        const mapping = {
            caring_individual: "caring",
            sentiment_individual: "sent",
            engagement_individual: "eng",
        };
        return mapping.hasOwnProperty(badgeType) ? mapping[badgeType as keyof typeof mapping] : badgeType;
    };

    const getBadgeImage = (badge: Badge) => {
        const level = badge.description.includes("90%") ? "90" : "80";
        const badgeType = mapBadgeType(badge.badge_type);
        try {
            return require(`../img/badges/badge_${badgeType}_${level}.png`);
        } catch (error) {
            console.error("Error loading image for badge:", badge);
            return require("../img/badges/sentiment_empty.png");
        }
    };

    return (
        <div className="my-badges-page-container flex flex-col mx-auto items-center" style={{ maxWidth: 450 }}>
            <Header showBackground={true}/>
    
            <div className="w-full px-4 mb-4">
                <button 
                    onClick={() => navigate("/dashboard")} 
                    className="flex items-center text-[#492CBB]"
                >
                    <img 
                        src={backArrow} 
                        alt="Back" 
                        className="w-11.63 h-24.5 mr-2"
                    />
                    <span className="text-[#492CBB] font-semibold text-[22px] leading-[22px]">Home</span> 
                </button>
            </div>

    
            <CounterBadges
                totalBadges={badges.length}
                engagementCount80={engagementCount80}
                engagementCount90={engagementCount90}
                caringCount80={caringCount80}
                caringCount90={caringCount90}
                sentimentCount80={sentimentCount80}
                sentimentCount90={sentimentCount90}
            />
    
            {/* Score */}
            <div className="bg-white rounded-xl mt-6 p-4 w-full">
                <div className="flex justify-between items-center mb-4">
                    <h4 className="font-semibold text-[16px] leading-[18px] text-[#53565A]">
                        Score ({badges.filter(badge => badge.badge_type !== "all_rounder").length})
                    </h4>
                    <span 
                        className="text-[#492CBB] font-semibold text-[14px] leading-[15.4px] underline cursor-pointer text-right"
                        onClick={() => navigate("/dashboard/allbadges")}
                    >
                        Check all
                    </span>
                </div>

                <div className="flex flex-row flex-wrap">
                    {scoreBadges.map((badge, index) => (
                        <div key={index} className="w-[30%] mr-[3%] mt-4 cursor-pointer relative flex flex-col items-center">
                            <img className="w-full" src={getBadgeImage(badge)} alt={badge.name} />
                            <p className="text-center text-dark mt-2">{badge.name}</p>
                            <p className="text-center text-gray-500 text-sm">{badge.description}</p>
                            
                            {badge.count && (
                                <span className="badge-score-counter">{badge.count}</span>
                            )}
                        </div>
                    
                    ))}
                </div>

            </div>
    
            {/* Frequency */}
            <div className="bg-white rounded-xl mt-6 p-4 w-full">
                <div className="flex justify-between items-center mb-4">
                    <h4 className="font-semibold text-[16px] leading-[18px] text-[#53565A]">
                        Frequency ({badges.filter(badge => badge.badge_type !== "all_rounder" && badge.streak && badge.streak > 1).length})
                    </h4>
                    <span 
                        className="text-[#492CBB] font-semibold text-[14px] leading-[15.4px] underline cursor-pointer text-right"
                        onClick={() => navigate("/dashboard/allbadges")}
                    >
                        Check all
                    </span>
                </div>
                <div className="flex flex-row flex-wrap">
                    {frequencyBadges.map((badge, index) => (
                        <div key={index} className="w-[30%] mr-[3%] mt-4 cursor-pointer relative flex flex-col items-center">
                            <img className="w-full" src={getBadgeImage(badge)} alt={badge.name} />
                            <p className="text-center text-dark mt-2">{badge.name}</p>
                            <p className="text-center text-gray-500 text-sm">{badge.description}</p>
                            
                            {badge.streak && (
                                <span className="badge-score-counter">{badge.streak}</span>
                            )}
                        </div>
                    ))}
                </div>
            </div>

    
            {/* All Categories */}
            <div className="bg-white rounded-xl mt-6 p-4 w-full">
                <div className="flex justify-between items-center mb-4">
                    <h4 className="font-semibold text-[16px] leading-[18px] text-[#53565A]">
                        All Categories ({allCategoriesBadges.length})
                    </h4>
                    <span 
                        className="text-[#492CBB] font-semibold text-[14px] leading-[15.4px] underline cursor-pointer text-right"
                        onClick={() => navigate("/dashboard/allbadges")}
                    >
                        Check all
                    </span>
                </div>
                <div className="flex flex-row flex-wrap">
                    {allCategoriesBadges.map((badge, index) => (
                        <div key={index} onClick={() => handleBadgeClick(badge)} className="w-[30%] mr-[3%] mt-4 cursor-pointer relative flex flex-col items-center">
                            <img className="w-full" src={require("../img/badges/badge_all_80.png")} alt={badge.name} />
                            <p className="text-center text-dark mt-2">{badge.name}</p>
                            <p className="text-center text-gray-500 text-sm">{badge.description}</p>

                            {badge.count && (
                                <span className="badge-score-counter">{badge.count}</span>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default MyBadgesPage;
