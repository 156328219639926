import React from "react";
import { useNavigate } from "react-router-dom";
import onboardingDetailsUpPage1 from "../../img/quickGuide/onboardingDetailsUpPage1.svg";
import onboardingDetailsDownPage1 from "../../img/quickGuide/onboardingDetailsDownPage1.svg";
import cloud1 from "../../img/quickGuide/cloud1.svg";
import cloud2 from "../../img/quickGuide/cloud2.svg";
import cloud3 from "../../img/quickGuide/cloud3.svg";

interface OnboardingProps {
  nextStep: () => void;
  prevStep: () => void;
  currentStep: number;
  showNavigationBar?: boolean;

}

const Onboarding05: React.FC<OnboardingProps> = ({ nextStep, prevStep, currentStep }) => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        height: "100vh",
        backgroundColor: "#F5F3FF",
        padding: "32px",
        position: "relative",
        textAlign: "center",
        overflow: "hidden",
      }}
    >

      <img
        src={onboardingDetailsUpPage1}
        alt="Background Wave Up"
        style={{
          position: "absolute",
          top: "0px",
          left: "0px",
          width: "200%",
          height: "auto",
          zIndex: 0,
          opacity: 0.75,
        }}
      />
      <img
        src={onboardingDetailsDownPage1}
        alt="Background Wave Down"
        style={{
          position: "absolute",
          bottom: "0px",
          right: "0px",
          width: "200%",
          height: "auto",
          zIndex: 0,
          opacity: 0.75,
          transform: "rotate(0)",
        }}
      />
      <button
        onClick={() => navigate("/dashboard/menu")}
        style={{
          position: "absolute",
          top: "16px",
          right: "16px",
          fontSize: "16px",
          fontWeight: "bold",
          color: "#6A4DF8",
          background: "none",
          border: "none",
          cursor: "pointer",
          zIndex: 1,
        }}
      >
        skip
      </button>

      <h1
        style={{
          fontSize: "30px", 
          fontWeight: 700, 
          color: "#220D4E", 
          fontFamily: "Graphik, sans-serif",
          lineHeight: "33px", 
          marginTop: "70px", 
          zIndex: 1,
          marginBottom: "-80px",
          maxWidth: "155px", 
        }}
      >
        Navigate with ease
      </h1>

      <div
        style={{
          position: "relative",
          width: "100%",
          maxWidth: "300px",
          zIndex: 1,
          marginBottom: "16px",
          marginRight:"90px",
          marginTop:"110px",
        }}
      >
        <img
          src={cloud1}
          alt="Cloud 1"
          style={{
            width: "100%",
            height: "auto",
          }}
        />
      </div>

      <div
        style={{
          position: "relative",
          width: "100%",
          maxWidth: "320px",
          zIndex: 1,
          marginBottom: "0px",
          marginRight:"-49px",
          marginTop:"-10px",
        }}
      >
        <img
          src={cloud2}
          alt="Cloud 2"
          style={{
            width: "100%",
            height: "auto",
          }}
        />
      </div>

      <div
        style={{
          position: "relative",
          width: "100%",
          maxWidth: "300px",
          zIndex: 1,
          marginBottom: "16px",
          marginRight:"-50px",
          marginTop:"-10px",
        }}
      >
        <img
          src={cloud3}
          alt="Cloud 3"
          style={{
            width: "100%",
            height: "auto",
          }}
        />
      </div>

      <p
        style={{
          fontSize: "20px",
          fontWeight: 400,
          color: "#220D4E", 
          marginBottom: "32px",
          maxWidth: "305px", 
          lineHeight: "22px", 
          zIndex: 1,
          textAlign: "center", 
        }}
      >
        Use these controls regularly to stay updated on your impact and teamwork.
      </p>

      {/* nav bar */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          maxWidth: "300px",
          zIndex: 1,
        }}
      >
        <button
          onClick={prevStep}
          style={{
            border: "none",
            backgroundColor: "transparent",
            fontSize: "24px",
            color: "#6A4DF8",
            cursor: "pointer",
          }}
        >
          &lt;
        </button>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "8px",
          }}
        >
          {Array(7)
            .fill(null)
            .map((_, index) => (
              <div
                key={index}
                style={{
                  width: index === currentStep ? "24px" : "8px", 
                  height: "8px",
                  borderRadius: index === currentStep ? "4px" : "50%",
                  backgroundColor: index === currentStep ? "#6A4DF8" : "#D0C1FF",
                  transition: "all 0.3s ease",
                }}
              ></div>
            ))}
        </div>
        <button
          onClick={nextStep}
          style={{
            border: "none",
            backgroundColor: "transparent",
            fontSize: "24px",
            color: "#6A4DF8",
            cursor: "pointer",
          }}
        >
          &gt;
        </button>
      </div>
    </div>
  );
};

export default Onboarding05;
