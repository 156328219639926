import React from "react";
import { useNavigate } from "react-router-dom";
import teamfloLogo from "../../img/quickGuide/teamfloLogo.svg";
import mainViewUpPart from "../../img/quickGuide/mainViewUpPart.svg";
import mainViewDownPart from "../../img/quickGuide/mainViewDownPart.svg";

interface Onboarding01Props {
  nextStep: () => void;
  showNavigationBar?: boolean;
}

const Onboarding01: React.FC<Onboarding01Props> = ({ nextStep }) => {
  const navigate = useNavigate();

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "#492CBB",
        overflow: "hidden",
        position: "relative",
      }}
    >
      <img
        src={mainViewUpPart}
        alt="Decorative Top Section"
        style={{
          position: "absolute",
          top: "0px",
          left: "0px",
          zIndex: 0,
          width: "100%",
          height: "auto",
        }}
      />
      <img
        src={mainViewDownPart}
        alt="Decorative Bottom Section"
        style={{
          position: "absolute",
          bottom: "0px",
          right: "0px",
          zIndex: 0,
          width: "100%",
          height: "auto",
          transform: "rotate(0deg)",
        }}
      />
      <div
        style={{
          maxWidth: "360px",
          width: "100%",
          padding: "20px",
          zIndex: 1,
          transform: "translateY(-200px)", 
        }}
      >
        <button
          onClick={() => navigate("/dashboard/menu")}
          style={{
            position: "absolute",
            top: "16px",
            right: "16px",
            fontSize: "16px",
            fontWeight: "bold",
            color: "#FFFFFF",
            background: "none",
            border: "none",
            cursor: "pointer",
            zIndex: 2,
            marginTop:"20px"
          }}
        >
          skip
        </button>
        <div style={{ textAlign: "left", marginTop: "150px" }}>
          <h1
            style={{
              fontSize: "51px",
              fontWeight: 700,
              color: "#FFFFFF",
              fontFamily: "Graphik, sans-serif",
              lineHeight: "58px",
              textAlign: "left",
              marginBottom: "20px",
            }}
          >
            Glad to <span style={{ fontStyle: "italic" }}>have you</span> on
          </h1>
          <img
            src={teamfloLogo}
            alt="TeamFlo Logo"
            style={{
              marginTop: "0",
              width: "295px",
              height: "63px",
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            marginTop: "50px",
          }}
        >
          <button
            onClick={nextStep}
            style={{
              width: "200px",
              height: "50px",
              fontSize: "16px",
              fontWeight: "bold",
              color: "#FFFFFF",
              backgroundColor: "#6F4FDF",
              borderRadius: "34px",
              border: "none",
              cursor: "pointer",
            }}
          >
            Let me in
          </button>
        </div>
      </div>
    </div>
  );
  
};

export default Onboarding01;
