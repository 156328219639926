

const badge_icons:any = {
    "engagement_individual": [
        require("../img/badges/badge_eng_80.png"),
        require("../img/badges/badge_eng_80_2.png"),
        require("../img/badges/badge_eng_90.png"),
        require("../img/badges/badge_eng_90_2.png"),
        require("../img/badges/badge_eng_long.png"),
    ],
    
    "caring_individual": [
        require("../img/badges/badge_caring_80.png"),
        require("../img/badges/badge_caring_80_2.png"),
        require("../img/badges/badge_caring_90.png"),
        require("../img/badges/badge_caring_90_2.png"),
        require("../img/badges/badge_caring_long.png"),
    ],
    
    "sentiment_individual": [
        require("../img/badges/badge_sent_80.png"),
        require("../img/badges/badge_sent_80_2.png"),
        require("../img/badges/badge_sent_90.png"),
        require("../img/badges/badge_sent_90_2.png"),
        require("../img/badges/badge_sent_long.png"),
    ],

    "all_rounder":[
        require("../img/badges/badge_all_80.png"),
        require("../img/badges/badge_all_90.png"),
    ],
}

export default badge_icons;