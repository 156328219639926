import React, { useContext } from "react";
import { DashboardContext } from "../context/DashboardContext";

import Ring from "./ring"; 
import { engagement_rgb } from "../config";

const filter_icon = require("../img/filter-icon.png");

interface SprintHistoryProps {
    sprints: any[];
}

const SprintHistory = ({ sprints }: SprintHistoryProps) => {
    const { setShowFilter, showFilter, selectedSprint, setSelectedSprint, setDates } = useContext(DashboardContext);

    sprints = sprints.slice(-5);
    console.log("sprints", sprints);
    return (
        <div
            className="flex flex-row justify-between my-4"
            style={{ maxWidth: 320, margin: "0 auto" }}
        >
            {sprints.map((sprint: any, index: number) => (
                <div
                    key={index}
                    onClick={() => {
                        setSelectedSprint(sprint);
                        setShowFilter(false);
                        setDates(sprint.start_date, sprint.end_date);
                    }}
                    className="w-[36px] h-[36px] relative cursor-pointer flex items-center justify-center"
                    style={{ opacity: selectedSprint === sprint ? 1 : 0.25 }}
                >
                    <Ring
                        color={engagement_rgb}
                        progress={sprint.overal ? sprint.overal : 0}
                        strokeWidth={4}
                        size={40} 
                        scale={1.4} 
                    />
                    {sprint.name && (
                        <p className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] text-xs">
                            {sprint.name.match(/\d+/)[0]}
                        </p>
                    )}
                </div>
            ))}
            <button
                onClick={() => {
                    setShowFilter(!showFilter);
                }}
                className="w-[36px] h-[36px] rounded-full bg-purple flex justify-center items-center"
            >
                <img src={filter_icon} className="w-[18px] h-auto" alt="Filter icon" />
            </button>
        </div>
    );
};

export default SprintHistory;
