import React, { useState, useEffect, useContext } from "react";
import { useSearchParams, useNavigate, Outlet, useLocation } from "react-router-dom";
//filter insights no se esta actualizando esto son los botones
//mirar insight manager
//team line charts esta plano
import { sentiment_rgb, engagement_rgb, caring_rgb } from "../config";
import { DashboardContext } from "../context/DashboardContext";
import { TeamfloAPI } from "../manager/apimanager";

import Header from "../components/header";
import SprintHistory from "../components/sprintHistory";
import Ring from "../components/ring";
import Insight from "../components/insight";
import LineChart from "../components/lineChart";
import TimeFilter from "../components/timeFilter";
import BestMetrics from "../components/BestMetrics";
import Badges from "../components/Badges_V2";
import NewBadgesPopup from "../components/NewBadgesPopup";
import BottomNavigation from "../components/BottomNavigation";

import InsightManager from "../manager/insightManager";
import MiniLineChart from "../components/miniLineChart";

const DashboardHomePage: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    let [searchParams, setSearchParams] = useSearchParams();

    const [showFilter, setShowFilter] = useState(false);
    const [loading, setLoading] = useState(true);
    const [name, setName] = useState('User');

    const [projects, setProjects] = useState<any[]>([]);
    const [project, setProject] = useState<any>();

    const [insights, setInsights] = useState<any[]>([]);
    const [filteredInsights, setFilteredInsights] = useState<any[]>([]);
    const [lineChartData, setLineChartData] = useState<any[]>([]);

    const [sprints, setSprints] = useState<any[]>([]);
    const [selectedSprint, setSelectedSprint] = useState<any>();
    const [badges, setBadges] = useState<any[]>([]);
    const [filters, setFilters] = useState<string[]>([]);

    const [fromDate, setFromDate] = useState<string>("");
    const [toDate, setToDate] = useState<string>("");

    const [selectedTab, setSelectedTab] = useState<"My stats" | "Team">("My stats");

    const [token, setToken] = useState<string | null>(null);

    const isDetailPage = 
    location.pathname === "/dashboard/badgedetails" || 
    location.pathname === "/dashboard/mybadges" || 
    location.pathname === "/dashboard/allbadges";

    const isMeVsTeamView = location.pathname === "/dashboard/meVsTeam";

    useEffect(() => {
        const fetchData = async () => {
            if (searchParams.has("token")) {
                const token = searchParams.get("token") as string;
                // console.log("Token desde URL:", token);
                localStorage.setItem("token", token); 
                // console.log("Token guardado en localStorage:", token);

                const result = await GetData(token);
    
                if (result) {
                    // console.log("Datos retornados desde GetData:", result);
                }
            } else {
                navigate("/"); 
            }
        };
        fetchData(); 
    }, []);
    

    useEffect(() => {
        LoadProjectDetails();
    }, [project]);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [location.pathname]);
    
    useEffect(() => {
        if (selectedSprint) {
            const updatedFilteredInsights = insights.filter((insight) => {
                const start = new Date(insight.date_start);
                const end = new Date(insight.date_end);
                const sprintStart = new Date(selectedSprint.start_date);
                const sprintEnd = new Date(selectedSprint.end_date);
    
                return start >= sprintStart && end <= sprintEnd;
            });
    
            setFilteredInsights(updatedFilteredInsights);
    
        }
    }, [selectedSprint, insights]);

    const LoadProjectDetails = async () => {
        if (project) {
            const token = searchParams.get("token") as string;
            //console.log("Loading project details for project:", project);
            const newInsights: any[] = [];
            const projectDetails = await TeamfloAPI.getProjectDetails(token, project.id);

            //console.log("Project details from TeamfloAPI:", projectDetails);
            projectDetails.insights.forEach((insight: any) => {
                newInsights.push(insight);
            });

            //console.log("All insights fetched:", newInsights);

            setInsights(newInsights);
            setFilteredInsights(newInsights);
            setLineChartData(newInsights);

            //console.log("LineChart Data from Dashboard:", newInsights);
            if (projectDetails.badges) {
                setBadges(projectDetails.badges);
            }

            if (newInsights.length > 0) {
                setToDate(newInsights[newInsights.length - 1].date_end);
                setFromDate(newInsights[newInsights.length - 1].date_start);
            } else {
                setToDate("");
                setFromDate("");
            }

            let newSprints = UpdateSprints(projectDetails.project.sprints, newInsights);
            newSprints = removeSprintsWithNoData(newSprints, newInsights);


            setSprints(newSprints);
            setSelectedSprint(newSprints[newSprints.length - 1]);

            setLoading(false);
        }
    };
    const UpdateSprints = (sprints: any[], insights: any[]) => {
    const newSprints = sprints
        .map((sprint: any) => {
            const filtered = insights.filter((insight: any) => {
                const start = new Date(insight.date_start);
                const end = new Date(insight.date_end);
                const sprint_start = new Date(sprint.start_date);
                const sprint_end = new Date(sprint.end_date);

                return start >= sprint_start && end <= sprint_end;
            });

            const overal = InsightManager.GetLatest(filtered, "overal_individual");
            if (overal) {
                sprint.overal = overal?.data?.value;
            } else {
                const engagement = InsightManager.GetLatest(filtered, "engagement_individual");
                if (engagement) {
                    sprint.overal = engagement?.data?.value;
                }
            }

            return sprint;
        })
        // Remove sprints that have no data
        .filter((sprint: any) => {
            const filtered = insights.filter((insight: any) => {
                const start = new Date(insight.date_start);
                const end = new Date(insight.date_end);
                const sprint_start = new Date(sprint.start_date);
                const sprint_end = new Date(sprint.end_date);

                return start >= sprint_start && end <= sprint_end;
            });

            return filtered.length > 0;
        });

    return newSprints;
};

const removeSprintsWithNoData = (sprints: any[], insights: any[]) => {
    // Remove sprints that have no data.
    return sprints.filter((sprint: any) => {
        const filtered = insights.filter((insight: any) => {
            const start = new Date(insight.date_start);
            const end = new Date(insight.date_end);
            const sprint_start = new Date(sprint.start_date);
            const sprint_end = new Date(sprint.end_date);

            return start >= sprint_start && end <= sprint_end;
        });

        return filtered.length > 0;
    });
};

const GetData = async (token: string) => {
    const data = await TeamfloAPI.getUser(token);

    if (data) {
        setName(data.first_name);
        setProjects(data.projects);
        setProject(data.projects[0]);

        if (data.projects[0]?.badges) {
            setBadges(data.projects[0].badges);
        }

        return { token, ...data }; 
    } else {
        navigate("/");
    }
};

    const ToggleFilter = (type: string) => {
        const newFilters = [...filters];
        if (filters.includes(type)) {
            setFilters(newFilters.filter((f: string) => f !== type));
        } else {
            setFilters([...newFilters, type]);
        }
    };

    const filterActive = (type: string) => {
        if (filters.length === 0) {
            return false;
        }
        return filters.includes(type);
    };

    if (loading) {
        return <div>Loading...</div>;
    }
    console.log("filtered", sprints);
    return (
        <DashboardContext.Provider
            value={{
                filters,
                setFilters,
                fromDate,
                toDate,
                projects,
                setProject,
                project,
                showFilter,
                setShowFilter,
                selectedSprint,
                setSelectedSprint,
                sprints,
                badges,
                insights,
                setDates: (fromDate: string, toDate: string) => {
                    setFromDate(fromDate);
                    setToDate(toDate);
                },
            }}
        >
            <div
                className="flex flex-col mx-auto items-center"
                style={{ maxWidth: 360,  display: "flex",alignItems: "center",justifyContent: "center",}}
            >
                
                {!(isDetailPage || isMeVsTeamView) && <Header  showBackground={true}/>}
                <div style={{ flex: 1, width: "100%", paddingBottom: "60px", }}>
                    {!(isDetailPage || isMeVsTeamView) && (
                        <>
                            {filteredInsights.length > 0 && (
                                <div>
                                    <SprintHistory sprints={sprints} />
    
                                    <div
                                        className="bg-white rounded-xl mt-6 p-6 w-full max-w-md"
                                        style={{ maxWidth: 360 }}
                                    >
                                        <h4
                                            className="font-bold text-dark mb-4"
                                            style={{ marginLeft: 7.5 }}
                                        >
                                            Your Performance
                                        </h4>
                                        <div
                                            className="flex flex-row mt-8"
                                            style={{ marginLeft: 11.5 }}
                                        >
                                            <div className="RingContainer w-[132px] h-[132px]">
                                                <Ring
                                                    color={sentiment_rgb}
                                                    progress={
                                                        InsightManager.GetLatest(
                                                            filteredInsights,
                                                            "sentiment_individual"
                                                        )?.data?.value || 0
                                                    }
                                                    strokeWidth={2.8}
                                                    size={40}
                                                    scale={1.43}
                                                    filter={"sentiment"}
                                                    isTeam={true}
                                                    showCenterValue={filters.includes(
                                                        "sentiment"
                                                    )}
                                                />
                                                <Ring
                                                    color={caring_rgb}
                                                    progress={
                                                        InsightManager.GetLatest(
                                                            filteredInsights,
                                                            "caring_individual"
                                                        )?.data?.value || 0
                                                    }
                                                    strokeWidth={3.5}
                                                    size={40}
                                                    scale={1.1}
                                                    filter={"caring"}
                                                    showCenterValue={filters.includes(
                                                        "caring"
                                                    )}
                                                />
                                                <Ring
                                                    color={engagement_rgb}
                                                    progress={
                                                        InsightManager.GetLatest(
                                                            filteredInsights,
                                                            "engagement_individual"
                                                        )?.data?.value || 0
                                                    }
                                                    strokeWidth={5}
                                                    size={40}
                                                    scale={0.77}
                                                    filter={"engagement"}
                                                    showCenterValue={filters.includes(
                                                        "engagement"
                                                    )}
                                                />
                                                {(filters.length === 0 ||
                                                    filters.length > 1) && (
                                                    <p className="text-xl absolute left-[50%] top-[50%] translate-x-[-40%] translate-y-[-50%] text-light-gray font-bold">
                                                        {
                                                            InsightManager.GetLatest(
                                                                filteredInsights,
                                                                "overal_individual"
                                                            )?.data?.value
                                                        }
                                                        %
                                                    </p>
                                                )}
                                            </div>
                                            <div className="flex-1 ml-8 flex flex-col justify-center">
                                                <div
                                                    onClick={() => ToggleFilter("engagement")}
                                                    style={{
                                                        opacity:
                                                            filters.length > 0 &&
                                                            !filters.includes("engagement")
                                                                ? 0.25
                                                                : 1,
                                                    }}
                                                    className="bg-light-purple w-full mb-1 rounded-lg p-2 flex flex-row cursor-pointer"
                                                >
                                                    <div className="w-[24px] mr-2">
                                                        <Ring
                                                            color={engagement_rgb}
                                                            progress={
                                                                InsightManager.GetLatest(
                                                                    filteredInsights,
                                                                    "engagement_individual"
                                                                )?.data?.value
                                                            }
                                                            strokeWidth={9}
                                                            size={40}
                                                            scale={1}
                                                        />
                                                    </div>
                                                    {filters.length > 0 && (
                                                        <p
                                                            style={{
                                                                fontWeight: filterActive(
                                                                    "engagement"
                                                                )
                                                                    ? "bold"
                                                                    : "regular",
                                                            }}
                                                        >
                                                            Engagement
                                                        </p>
                                                    )}
                                                    {filters.length === 0 && <p>Engagement</p>}
                                                </div>
    
                                                <div
                                                    onClick={() => ToggleFilter("caring")}
                                                    style={{
                                                        opacity:
                                                            filters.length > 0 &&
                                                            !filters.includes("caring")
                                                                ? 0.25
                                                                : 1,
                                                    }}
                                                    className="bg-light-purple w-full mb-1 rounded-lg p-2 flex flex-row cursor-pointer"
                                                >
                                                    <div className="w-[24px] mr-2">
                                                        <Ring
                                                            color={caring_rgb}
                                                            progress={
                                                                InsightManager.GetLatest(
                                                                    filteredInsights,
                                                                    "caring_individual"
                                                                )?.data?.value
                                                            }
                                                            strokeWidth={9}
                                                            size={40}
                                                            scale={1}
                                                        />
                                                    </div>
                                                    {filters.length > 0 && (
                                                        <p
                                                            style={{
                                                                fontWeight: filterActive(
                                                                    "caring"
                                                                )
                                                                    ? "bold"
                                                                    : "regular",
                                                            }}
                                                        >
                                                            Caring
                                                        </p>
                                                    )}
                                                    {filters.length === 0 && <p>Caring</p>}
                                                </div>
    
                                                <div
                                                    onClick={() => ToggleFilter("sentiment")}
                                                    style={{
                                                        opacity:
                                                            filters.length > 0 &&
                                                            !filters.includes("sentiment")
                                                                ? 0.25
                                                                : 1,
                                                    }}
                                                    className="bg-light-purple w-full mb-1 rounded-lg p-2 flex flex-row cursor-pointer"
                                                >
                                                    <div className="w-[24px] mr-2">
                                                        <Ring
                                                            color={sentiment_rgb}
                                                            progress={
                                                                InsightManager.GetLatest(
                                                                    filteredInsights,
                                                                    "sentiment_individual"
                                                                )?.data?.value
                                                            }
                                                            strokeWidth={9}
                                                            size={40}
                                                            scale={1}
                                                        />
                                                    </div>
                                                    {filters.length > 0 && (
                                                        <p
                                                            style={{
                                                                fontWeight: filterActive(
                                                                    "sentiment"
                                                                )
                                                                    ? "bold"
                                                                    : "regular",
                                                            }}
                                                        >
                                                            Sentiment
                                                        </p>
                                                    )}
                                                    {filters.length === 0 && <p>Sentiment</p>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
    
                                    <div
                                        className="bg-white rounded-xl mt-6 p-6 w-full max-width-md"
                                        style={{ maxWidth: 360 }}
                                    >
                                        <LineChart
                                            insights={lineChartData}
                                            showTeamMetrics={false}
                                        />
                                    </div>
                                </div>
                            )}
    
                            {(filters.length === 0 || filters.includes("engagement")) && (
                                <Insight
                                    title={"Engagement"}
                                    color={engagement_rgb}
                                    insight={InsightManager.GetLatest(
                                        filteredInsights,
                                        "engagement_individual"
                                    )}
                                />
                            )}
                            {(filters.length === 0 || filters.includes("caring")) && (
                                <Insight
                                    title={"Caring"}
                                    color={caring_rgb}
                                    insight={InsightManager.GetLatest(
                                        filteredInsights,
                                        "caring_individual"
                                    )}
                                />
                            )}
                            {(filters.length === 0 || filters.includes("sentiment")) && (
                                <Insight
                                    title={"Sentiment"}
                                    color={sentiment_rgb}
                                    insight={InsightManager.GetLatest(
                                        filteredInsights,
                                        "sentiment_individual"
                                    )}
                                />
                            )}
    
                            <Badges badges={badges} />
    
                            {filteredInsights.length === 0 && (
                                <div>
                                    <p className="text-center text-gray-500 mt-8 text-xl">
                                        No insights found for <br /> this sprint:{" "}
                                        {selectedSprint?.name}
                                    </p>
                                    <button
                                        className="bg-purple font-bold text-white rounded-xl px-8 py-2 mt-12"
                                        onClick={() => setShowFilter(true)}
                                    >
                                        Look for more data
                                    </button>
                                </div>
                            )}
    
                            <TimeFilter sprints={sprints} />
                            <NewBadgesPopup badges={badges} />
                        </>
                    )}
    
                    {/* Renderiza la subruta */}
                    <Outlet />
                </div>
    
                {/* Bottom Navigation */}
                {!isDetailPage && !isMeVsTeamView && (
                    <div
                        style={{
                            position: "fixed",
                            bottom: 0,
                            width: "100%",
                            height: "58px",
                            maxWidth: 360,
                            backgroundColor: "#fff",
                            zIndex: 1000,
                        }}
                    >
                        <BottomNavigation
                            projectName={project?.name || "Project"}
                            projectCount={projects?.length || 1}
                            selectedTab={isMeVsTeamView ? "Team" : "My stats"}
                            onSelectTab={(tab) => {
                                if (tab === "Team") {
                                    navigate("/dashboard/meVsTeam");
                                } else {
                                    navigate("/dashboard");
                                }
                            }}
                            lineChartDataOpt={insights}
                        />
                    </div>
                )}
            </div>
        </DashboardContext.Provider>
    );
    
    
};

export default DashboardHomePage;
