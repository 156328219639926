import React from "react";
import { useNavigate } from "react-router-dom";
import onboardingDetailsUpPage1 from "../../img/quickGuide/onboardingDetailsUpPage1.svg";
import onboardingDetailsDownPage1 from "../../img/quickGuide/onboardingDetailsDownPage1.svg";

interface OnboardingProps {
  nextStep: () => void;
  prevStep: () => void;
  currentStep: number;
  showNavigationBar?: boolean;
}

const Onboarding07: React.FC<OnboardingProps> = ({ nextStep, prevStep, currentStep }) => {
  const navigate = useNavigate();

  const token = localStorage.getItem("token");

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        height: "100vh",
        backgroundColor: "#F5F3FF",
        padding: "32px",
        position: "relative",
        textAlign: "center",
        overflow: "hidden",
      }}
    >
      <img
        src={onboardingDetailsUpPage1}
        alt="Background Wave Up"
        style={{
          position: "absolute",
          top: "0px",
          left: "0px",
          width: "200%",
          height: "auto",
          zIndex: 0,
          opacity: 0.75,
        }}
      />

      <img
        src={onboardingDetailsDownPage1}
        alt="Background Wave Down"
        style={{
          position: "absolute",
          bottom: "0px",
          right: "0px",
          width: "200%",
          height: "auto",
          zIndex: 0,
          opacity: 0.75,
          transform: "rotate(0)",
        }}
      />

      <h1
        style={{
          fontSize: "61px",
          fontWeight: 700,
          color: "#220D4E",
          fontFamily: "Graphik, sans-serif",
          lineHeight: "56px",
          textAlign: "left",
          marginBottom: "0px",
          marginTop: "180px",
          zIndex: 1,
        }}
      >
        Ready to make an impact?
      </h1>

      <p
        style={{
          fontSize: "18px",
          fontWeight: 400,
          color: "#220D4E",
          marginBottom: "0px",
          maxWidth: "305px",
          lineHeight: "22px",
          textAlign: "left",
          zIndex: 1,
        }}
      >
        Start exploring your stats, earn badges, and reach new milestones with
        your team!
      </p>

      <button
        onClick={() =>
          token
            ? navigate(`/dashboard?token=${token}`)
            : console.error("No token available")
        }
        style={{
          width: "201px",
          height: "48px",
          fontSize: "16px",
          fontWeight: "bold",
          color: "#FFFFFF",
          backgroundColor: "#492CBB",
          borderRadius: "34px",
          border: "none",
          cursor: "pointer",
          zIndex: 1,
          marginBottom: "100px",
          position: "relative",
          left: "-56px",
          top: "-30px",
        }}
      >
        Let’s Get Started
      </button>

      <p
        style={{
          fontSize: "20px",
          fontWeight: 400,
          color: "#220D4E",
          lineHeight: "22px",
          textAlign: "center",
          marginTop: "-100px",
          marginBottom: "80px",
          zIndex: 1,
        }}
      >
        Need a quick refresher? Visit the Help section anytime.
      </p>

      {/* Nav Bar */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          maxWidth: "300px",
          zIndex: 1,
        }}
      >
        <button
          onClick={prevStep}
          style={{
            border: "none",
            backgroundColor: "transparent",
            fontSize: "24px",
            color: "#6A4DF8",
            cursor: "pointer",
          }}
        >
          &lt;
        </button>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "8px",
          }}
        >
          {Array(7)
            .fill(null)
            .map((_, index) => (
              <div
                key={index}
                style={{
                  width: index === currentStep ? "24px" : "8px",
                  height: "8px",
                  borderRadius: index === currentStep ? "4px" : "50%",
                  backgroundColor: index === currentStep ? "#6A4DF8" : "#D0C1FF",
                  transition: "all 0.3s ease",
                }}
              ></div>
            ))}
        </div>
        <button
          onClick={nextStep}
          style={{
            border: "none",
            backgroundColor: "transparent",
            fontSize: "24px",
            color: "#6A4DF8",
            cursor: "pointer",
          }}
        >
          &gt;
        </button>
      </div>
    </div>
  );
};

export default Onboarding07;
