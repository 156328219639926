import { useState, useEffect, useContext } from "react";
import { DashboardContext } from "../context/DashboardContext";
import { engagement_rgb, sentiment_rgb, caring_rgb } from "../config";
import Line from "./Line";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import InsightManager from "../manager/insightManager";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);

const options: any = {
  scales: {
    y: {
      min: 0,
      max: 100,
      beginAtZero: true,
      ticks: {
        callback: (value: number) => `${value}%`,
      },
    },
  },
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
  },
  elements: {
    line: {
      tension: 0.4,
      borderWidth: 4,
    },
    point: {
      radius: 1,
      borderWidth: 1,
    },
  },
};

const MakeDataSet = (values: number[], label: string, color: string, isTeam: boolean = false): any => {
  return {
    label: label,
    data: values,
    fill: false,
    borderColor: color,
    tension: 0.1,
    borderDash: isTeam ? [5, 5] : undefined, 
    pointRadius: isTeam ? 5 : 1,
    pointBorderWidth: isTeam ? 2 : 1,
  };
};

const fillNulls = (array: (number | null)[]): number[] => {
  for (let i = 1; i < array.length; i++) {
    if (array[i] === null) {
      array[i] = array[i - 1];
    }
  }
  for (let i = array.length - 2; i >= 0; i--) {
    if (array[i] === null) {
      array[i] = array[i + 1];
    }
  }
  return array as number[];
};

interface LineChartProps {
  insights: any;
  showTeamMetrics?: boolean;
}


const LineChart: React.FC<LineChartProps> = ({ insights, showTeamMetrics = true }) => {
  const { filters, setFilters, sprints } = useContext(DashboardContext);
  // console.log("asasdqwe",insights);
  const [data, setData] = useState<any>(null);

  useEffect(() => {
    if (insights.length > 0) {
      SetupData();
    }
  }, [filters, insights]);

  const GetMetrics = (insights: any[], type: string): any => {
    return InsightManager.GetLabelsAndValues(insights, type, 5) || {};
  };

  const SetupData = () => {

    const engagement_metrics = GetMetrics(insights, "engagement_individual");
    const caring_metrics = GetMetrics(insights, "caring_individual");
    const sentiment_metrics = GetMetrics(insights, "sentiment_individual");
  
    const engagement_metrics_team = showTeamMetrics ? GetMetrics(insights, "engagement_team") : {};
    const caring_metrics_team = showTeamMetrics ? GetMetrics(insights, "caring_team") : {};
    const sentiment_metrics_team = showTeamMetrics ? GetMetrics(insights, "sentiment_team") : {};
  

    const allKeys = new Set([
      ...Object.keys(engagement_metrics),
      ...Object.keys(caring_metrics),
      ...Object.keys(sentiment_metrics),
      ...Object.keys(engagement_metrics_team),
      ...Object.keys(caring_metrics_team),
      ...Object.keys(sentiment_metrics_team),
    ]);
    let labels: string[] = Array.from(allKeys).sort();

    const sprintLabels = labels.map((date) => {
      if (!sprints || !Array.isArray(sprints)) {
        console.warn("sprints no está definido o no es un array");
        return date; 
      }
    
      const sprint = sprints.find((sprint: any) => {

        const sprintStart = sprint.start_date.slice(5); 
        const sprintEnd = sprint.end_date.slice(5); 
        const currentDate = date; 

        return currentDate >= sprintStart && currentDate <= sprintEnd;
      });
    
     
      const sprintNumber = sprint?.name ? sprint.name.match(/\d+/)?.[0] : null;
      return sprintNumber ? Number(sprintNumber) : date;
    });

    const datasets = [];
    if (filters.length === 0 || filters.includes("engagement")) {
      const engagementData = labels.map((key) => engagement_metrics[key] || null);
      const engagementDataTeam = labels.map((key) => engagement_metrics_team[key] || null);
      fillNulls(engagementData);
      fillNulls(engagementDataTeam);
      datasets.push(MakeDataSet(engagementData, "Engagement Individual", `rgba(${engagement_rgb}, 1)`));
      if (showTeamMetrics) {
        datasets.push(MakeDataSet(engagementDataTeam, "Engagement Team", `rgba(${engagement_rgb}, 0.6)`, true));
      }
    }
    if (filters.length === 0 || filters.includes("caring")) {
      const caringData = labels.map((key) => caring_metrics[key] || null);
      const caringDataTeam = labels.map((key) => caring_metrics_team[key] || null);
      fillNulls(caringData);
      fillNulls(caringDataTeam);
      datasets.push(MakeDataSet(caringData, "Caring Individual", `rgba(${caring_rgb}, 1)`));
      if (showTeamMetrics) {
        datasets.push(MakeDataSet(caringDataTeam, "Caring Team", `rgba(${caring_rgb}, 0.6)`, true));
      }
    }
    if (filters.length === 0 || filters.includes("sentiment")) {
      const sentimentData = labels.map((key) => sentiment_metrics[key] || null);
      const sentimentDataTeam = labels.map((key) => sentiment_metrics_team[key] || null);
      fillNulls(sentimentData);
      fillNulls(sentimentDataTeam);
      datasets.push(MakeDataSet(sentimentData, "Sentiment Individual", `rgba(${sentiment_rgb}, 1)`));
      if (showTeamMetrics) {
        datasets.push(MakeDataSet(sentimentDataTeam, "Sentiment Team", `rgba(${sentiment_rgb}, 0.6)`, true));
      }
    }

    setData({
      labels: sprintLabels,
      datasets: datasets,
    });
  };
  
  const ToggleFilter = (type: string) => {
    if (filters.includes(type)) {
      setFilters(filters.filter((f: string) => f !== type));
    } else {
      setFilters([...filters, type]);
    }
  };

  return (
    <div className="bg-white rounded-lg mt-4 " style={{ width: 320 }}>
      <div className="flex justify-between items-center mb-4">
        <h4 className="font-bold text-dark">
          {showTeamMetrics ? "Performance" : "Sprint performance over time"}
        </h4>
        {showTeamMetrics && (
          <div className="flex items-center space-x-4">
            <div className="flex items-center">
              <div
                className="w-6 h-1 bg-dark mr-1"
                style={{ backgroundColor: "#492CBB" }}
              />
              <span className="text-xs font-medium">You</span>
            </div>
            <div className="flex items-center">
              <div
                className="w-6 h-1 border-t-2 border-dark mr-1"
                style={{
                  borderStyle: "dashed",
                  borderColor: "#492CBB",
                }}
              />
              <span className="text-xs font-medium">Team</span>
            </div>
          </div>
        )}
      </div>
  
      <div className="flex justify-around mb-4">
        {(["engagement", "caring", "sentiment"] as const).map((metric) => (
          <button
            key={metric}
            onClick={() => ToggleFilter(metric)}
            className={`px-4 py-1 rounded-full border text-sm font-normal`}
            style={{
              color:
                metric === "engagement"
                  ? "#B16000"
                  : metric === "caring"
                  ? "#6D2077"
                  : "#0A8F98",
              borderColor:
                metric === "engagement"
                  ? "#B16000"
                  : metric === "caring"
                  ? "#6D2077"
                  : "#0A8F98",
              opacity: filters.includes(metric) || filters.length === 0 ? 1 : 0.5,
            }}
          >
            {metric.charAt(0).toUpperCase() + metric.slice(1)}
          </button>
        ))}
      </div>
  
      {/* Grafico */}
      {data && (
        <Line
          chartName="SPRINT"
          width={320}
          height={200}
          data={data}
          options={options}
        />
      )}
    </div>
  );
};

export default LineChart;
