import React, { useState } from "react";
import Header from "../components/header";

const ContactUs: React.FC = () => {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    console.log("Form Data:", { fullName, email, message });
  };

  return (
    <div
      className="min-h-screen flex flex-col"
      style={{
        backgroundColor: "#4E2AC2",
        paddingTop: "20px",
      }}
    >
      {/* Header */}
      <Header showBackground={false} />

      <div
        className="flex-grow flex items-center justify-center"
        style={{
          maxWidth: "360px", 
          margin: "0 auto",
        }}
      >
        {/* Contact Us Section */}
        <div className="px-6 text-white w-full">
          <h1
            className="text-[40px] font-bold text-yellow-500 mb-6 text-left"
            style={{ lineHeight: "40px", marginBottom: "40px", marginTop: "-180px" }}
          >
            Contact us
          </h1>

          <form className="space-y-6" onSubmit={handleSubmit}>
            {/* Full Name */}
            <div>
              <label
                className="block text-[25px] font-medium text-white mb-1"
                htmlFor="fullName"
                style={{ lineHeight: "27.5px" }}
              >
                Full name*
              </label>
              <input
                id="fullName"
                type="text"
                placeholder="Enter your full name"
                className="w-full p-3 bg-transparent border text-white placeholder-white placeholder-opacity-50 rounded-lg"
                style={{
                  border: "0.5px solid #FCB514",
                  borderRadius: "10px",
                  padding: "8px",
                  marginTop: "10px",
                }}
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                required
              />
            </div>

            {/* Email */}
            <div>
              <label
                className="block text-[25px] font-medium text-white mb-1"
                htmlFor="email"
                style={{ lineHeight: "27.5px" }}
              >
                Email*
              </label>
              <input
                id="email"
                type="email"
                placeholder="Enter your email"
                className="w-full p-3 bg-transparent border text-white placeholder-white placeholder-opacity-50 rounded-lg"
                style={{
                  border: "0.5px solid #FCB514",
                  borderRadius: "10px",
                  padding: "8px",
                  marginTop: "10px",
                }}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>

            {/* Message */}
            <div>
              <label
                className="block text-[25px] font-medium text-white mb-1"
                htmlFor="message"
                style={{ lineHeight: "27.5px" }}
              >
                Message*
              </label>
              <textarea
                id="message"
                placeholder="Type here your message..."
                className="w-full p-3 bg-transparent border text-white placeholder-white placeholder-opacity-50 rounded-lg h-28"
                style={{
                  border: "0.5px solid #FCB514",
                  borderRadius: "10px",
                  padding: "8px",
                  marginTop: "10px",
                }}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                required
              ></textarea>
            </div>

            {/* Submit Button */}
            <button
              type="submit"
              className="w-full py-3 text-[25px] font-semibold text-white flex items-center justify-center hover:text-black transition"
              style={{
                lineHeight: "29.3px",
                whiteSpace: "nowrap",
                backgroundColor: "transparent",
              }}
            >
              <span style={{ marginRight: "10px" }}>Send your message</span>
              <svg
                width="45"
                height="36"
                viewBox="0 0 45 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.174316 17.263L36.4046 17.7937"
                  stroke="url(#paint0_linear_1745_3551)"
                  strokeWidth="2.44591"
                />
                <path
                  d="M43.0405 28.1829L43.3273 7.862"
                  stroke="url(#paint1_linear_1745_3551)"
                  strokeWidth="2.44591"
                />
                <path
                  d="M21.9077 1.04524L37.7666 17.8199L21.8379 34.5373"
                  stroke="url(#paint2_linear_1745_3551)"
                  strokeWidth="2.44591"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_1745_3551"
                    x1="0.181682"
                    y1="16.7631"
                    x2="36.4119"
                    y2="17.2969"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#FCB514" stopOpacity="0" />
                    <stop offset="1" stopColor="#FCB514" />
                  </linearGradient>
                  <linearGradient
                    id="paint1_linear_1745_3551"
                    x1="49.741"
                    y1="16.4371"
                    x2="40.7415"
                    y2="16.3443"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#FCB514" stopOpacity="0" />
                    <stop offset="1" stopColor="#FCB514" />
                  </linearGradient>
                  <linearGradient
                    id="paint2_linear_1745_3551"
                    x1="22.3615"
                    y1="1.04618"
                    x2="22.2925"
                    y2="34.5382"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset="1" stopColor="#FCB514" />
                  </linearGradient>
                </defs>
              </svg>
            </button>
          </form>
        </div>
      </div>
    </div>
  );

};

export default ContactUs;
