import React from "react"

import Ring from "./ring"
import EmojiBar from "./EmojiBar";

const exclamationicon = require('../img/exclamation-icon.png');

interface InsightsProps{
    title:string;
    insight: any;
    color: string;
}

const labels :any = {
    "caring_individual":"Caring",
    "engagement_individual":"Engagement",
    "sentiment_individual":"Sentiment"
}


const borderColors: any = {
    "caring_individual":"rgba(48, 180, 187, 0.3)",
    "engagement_individual":"rgba(222, 122, 4, 0.3)",
    "sentiment_individual":"rgba(135, 43, 147, 0.3)"
};

const Insight: React.FC<InsightsProps> = ({insight, title, color}) => {
    if(!insight){
        return null;
    }

    const c = `rgba(${color}, 1)`;

    return(
        <div className="bg-white rounded-xl mt-4 pb-2" style={{maxWidth:360}}>
            <h3 className="font-bold text-2xl p-4 max-w-[70%]" style={{color:c}}>{insight.data.title}</h3>
            <div className="w-full mb-4" style={{borderWidth:"3px", borderColor: c}}></div>
            <p className="text-gray font-medium text-xl leading-5 w-full px-6 mt-2 mb-4">
                {insight.data.subtitle}
            </p>
            <EmojiBar insight={insight}></EmojiBar>
            <ul className="text-gray mt-3 text-left">
                {insight.message_content.split("|").map((m:string, i:number) => {
                    return <li 
                        style={{
                            borderColor:borderColors[insight.insight_type],
                            borderTopWidth:"1px",
                            borderStyle:"dashed"
                        }}
                        className="mb-4 px-6 pt-4 leading-5" 
                        key={i}>
                            {m}
                        </li>
                })}
                <li 
                    style={{
                        borderColor:borderColors[insight.insight_type],
                        borderTopWidth:"1px",
                        borderStyle:"dashed"
                    }}
                    className="mb-2 px-6 pt-4 font-medium text-light-gray text-sm" 
                >{labels[insight.insight_type]}</li>
            </ul>
        {/**
         * 
        <div className="w-full mt-4 rounded-xl fade-in flex flex-col justify-center pt-8 text-center" style={{maxWidth:424, backgroundColor:"#F8F6FF"}}>
            
            <div className="mx-auto mb-2" style={{width:46}}>
                <Ring 
                color={color}
                progress={insight.data.value}
                strokeWidth={8}
                size={45}
                ></Ring>
            </div>
            <h3 className="text-purple font-bold text-2xl" >{title}</h3>
            <p className="w-full px-12 text-lg mx-auto mt-2 leading-5 text-gray-700 mb-4">{descriptions[insight.insight_type]?descriptions[insight.insight_type]:"---"}</p>

            <div className="bg-white py-6 rounded-b-2xl">
                <EmojiBar insight={insight}></EmojiBar>
                <h3 className="text-gray font-bold text-3xl mt-4">{insight.data.title}</h3>
                <p className="text-gray font-medium text-xl leading-5 w-full px-6 my-2">
                    {insight.data.subtitle}
                </p>
                <ul className="text-gray mt-3 ml-4 text-left px-6">
                    
                    {insight.message_content.split(",").map((m:string, i:number) => {
                        return <li className="mb-2 p-4 rounded-xl leading-5 pl-10 relative" key={i} style={{backgroundColor:bgColors[insight.insight_type]}}>
                                {m}
                                <div className="w-2 h-2 rounded-full absolute left-4 top-[40%]" style={{backgroundColor:pointColors[insight.insight_type]}}></div>
                                </li>

                    })}
                </ul>
            </div>

        </div>
        */}

        </div>
    )   
}

export default Insight;
